import React, {forwardRef, useEffect, useMemo, useRef} from "react";
import {
  useFormContext,
  Controller,
  UseFieldArrayAppend,
} from "react-hook-form";
import { SelectBox } from "@/components/Form/SelectBox";
import { MESSAGE_NO_E08 } from "@/config";

export type ApplyArea = {
  id: string;
  checked: boolean;
  area_type: number | null;
  sales_product_id: number | null;
  sales_product_id2: number | null;
  error_area_type: string | null;
  error: string | null;
  duplicate_index: number | null;
};

interface Props {
  applyArea: ApplyArea;
  index: number;
  length: number;
  areas: any[];
  append: UseFieldArrayAppend<any, "areas">;
  showAppendApplyAreaContainer: boolean;
}

interface Methods {}

export const ApplyAreaForm = forwardRef<Methods, Props>((props, ref) => {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<{ areas: Array<ApplyArea> }>();

  const { applyArea, index, length, areas, append, showAppendApplyAreaContainer } = props;

  const watchType = watch(`areas.${index}.area_type`);
  const watchPrefId = watch(`areas.${index}.sales_product_id2`);

  const filteredCities = useMemo(() => {
    if (watchType && watchPrefId) {
      return areas[watchType - 1].children.find(
        (area: any) => area.id == watchPrefId
      ).children;
    } else {
      return [];
    }
  }, [watchPrefId]);

  const convertAreasToOptions = (areas: any[]) => {
    return areas.map((area: any) => {
      return { label: area.name, value: area.id };
    });
  };

  const appendApplyArea = () => {
    append({
      checked: false,
      type: 0,
      sales_product_id: null,
      sales_product_id2: null,
    });
  };

  useEffect(() => {
    if (watchPrefId) {
      setValue(`areas.${index}.sales_product_id`, null);
    }
  }, [index, setValue, watchPrefId]);

  return (
    <tbody key={applyArea.id}>
      <tr>
        <td>
          <label className="h-25">
            {length > 1 && (
              <input type="checkbox" {...register(`areas.${index}.checked`)} />
            )}
            申込エリア{index == 0 && length == 1 ? "" : index + 1}
            <span className="required">必須</span>
          </label>
        </td>
        <td>
          <Controller
            {...register(`areas.${index}.area_type`, {
              required: MESSAGE_NO_E08(`申込エリア${index + 1}`),
            })}
            render={({ field }) => (
              <div
                className={`uk-grid-small uk-grid ${
                  applyArea.error_area_type || errors.areas?.[index]?.area_type
                    ? "error-form-grid"
                    : ""
                }`}
              >
                {areas.map((area, i) => {
                  return (
                    <div key={area.id}>
                      <label>
                        <input
                          type="radio"
                          {...field}
                          onChange={(e) => {
                            const value = i + 1;
                            setValue(`areas.${index}.area_type`, value);
                            if (value == 1) {
                              setValue(`areas.${index}.sales_product_id`, 1);
                            } else {
                              setValue(`areas.${index}.sales_product_id`, null);
                              setValue(
                                `areas.${index}.sales_product_id2`,
                                null
                              );
                            }
                          }}
                          value={i + 1}
                          checked={watchType == i + 1}
                        />
                        {area.name}
                      </label>
                    </div>
                  );
                })}
              </div>
            )}
          />
        </td>
      </tr>
      {(watchType == 2 || watchType == 4) && areas.length > watchType - 1 && (
        <tr>
          <td>
            <p className="uk-margin-small-left">
              ▶︎{areas[watchType - 1].name}
              <span className="required">必須</span>
            </p>
          </td>
          <td>
            <SelectBox
              className="uk-width-1-3"
              name={`areas.${index}.sales_product_id`}
              options={convertAreasToOptions(areas[watchType - 1].children)}
              register={register}
              rules={{
                required: MESSAGE_NO_E08(
                  `申込エリア${index + 1} > ${areas[watchType - 1].name}`
                ),
              }}
              error={
                applyArea.error ??
                errors.areas?.[index]?.sales_product_id?.message
              }
            />
          </td>
        </tr>
      )}
      {watchType == 3 && areas.length > watchType - 1 && (
        <>
          <tr>
            <td>
              <p className="uk-margin-small-left">
                ▶︎都道府県<span className="required">必須</span>
              </p>
            </td>
            <td>
              <SelectBox
                className="uk-width-1-3"
                name={`areas.${index}.sales_product_id2`}
                options={convertAreasToOptions(areas[watchType - 1].children)}
                register={register}
                rules={{
                  required: MESSAGE_NO_E08(`申込エリア${index + 1} > 都道府県`),
                }}
                error={
                  applyArea.error ??
                  errors.areas?.[index]?.sales_product_id2?.message
                }
              />
            </td>
          </tr>
          <tr>
            <td>
              <p className="uk-margin-small-left">
                ▶︎市区町村<span className="required">必須</span>
              </p>
            </td>
            <td>
              <SelectBox
                className="uk-width-1-3"
                name={`areas.${index}.sales_product_id`}
                options={convertAreasToOptions(filteredCities)}
                register={register}
                rules={{
                  required: MESSAGE_NO_E08(`申込エリア${index + 1} > 市区町村`),
                }}
                error={
                  applyArea.error ??
                  errors.areas?.[index]?.sales_product_id?.message
                }
              />
            </td>
          </tr>
        </>
      )}
      {index == length - 1 && showAppendApplyAreaContainer && (
        <tr>
          <td className="bg-white">申込エリア追加</td>
          <td>
            <button
              className="uk-button--x uk-button-cancel uk-margin-left"
              onClick={appendApplyArea}
            >
              ＋エリア追加ボタン
            </button>
          </td>
        </tr>
      )}
    </tbody>
  );
});
