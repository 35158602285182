import create from 'zustand';

export type ApplyForm = {
    areas: Array<any>;
    attributes: Array<any>;
    files0: Array<any>;
    files1: Array<any>;
    files2: Array<any>;
    files3: Array<any>;
};

type ApplyFormStore = {
    applyForm: ApplyForm | null;
    attributes: Array<any>;
    areas: Array<any>;
    businessDay: string;
    taxRate: number;
    estimateAmount: number;
    showQuotationOrderText: boolean;
    setApplyForm: (applyForm: ApplyForm | null) => void;
    setAttributes: (attributes: Array<any>) => void;
    setAreas: (areas: Array<any>) => void;
    setBusinessDay: (businessDay: string) => void;
    setTaxRate: (taxRate: number) => void;
    setEstimateAmount: (estimateAmount: number) => void;
    setShowQuotationOrderText: (bool: boolean) => void;
};

export const useApplyFormStore = create<ApplyFormStore>((set) => ({
    applyForm: null,
    attributes: [],
    areas: [],
    businessDay: '',
    taxRate: 0.1,
    estimateAmount: 0,
    showQuotationOrderText: true,
    setApplyForm: (applyForm) =>
        set((state) => ({
            applyForm: applyForm,
        })),
    setAttributes: (attributes) =>
        set((state) => ({
            attributes: attributes,
        })),
    setAreas: (areas) =>
        set((state) => ({
            areas: areas,
        })),
    setBusinessDay: (businessDay) =>
        set((state) => ({
            businessDay: businessDay,
        })),
    setTaxRate: (taxRate: number) => set((state) => ({ taxRate: taxRate })),
    setEstimateAmount: (estimateAmount: number) =>
        set((state) => ({ estimateAmount: estimateAmount })),
    setShowQuotationOrderText: (bool) =>
      set((state) => ({
          showQuotationOrderText: bool,
      })),
}));
