import React, { useRef } from 'react';
import { RegisterOptions, useFieldArray, useFormContext } from 'react-hook-form';

interface props {
  name: string;
  multiple?: boolean;
  rules?: RegisterOptions;
  description?: string;
}

export const FileSelect: React.FC<props> = ({ name, rules, multiple, description }) => {
  const { register, control, formState: { errors }, } = useFormContext();
  const hiddenInputRef = useRef<HTMLInputElement | null>(null);
  const { ref: registerRef, ...rest } = register(name, rules);
  const { fields, append, remove } = useFieldArray({
    control,
    name: name,
  });
  const error = errors?.[name]?.root?.message;

  const onClickFileSelect = () => {
    if (hiddenInputRef?.current) {
      hiddenInputRef.current.click();
    }
  }

  const handleAddFiles = (event: any) => {
    const uploadedFiles = Array.from(event.target.files);

    const files = uploadedFiles.map((file) => ({
      file
    }));

    append(files);

    if (hiddenInputRef?.current) {
      hiddenInputRef.current.value = '';
    }
  };

  const formatFileSize = (sizeInBytes: number) => {
    if (sizeInBytes === 0) return '0 Bytes';
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(sizeInBytes) / Math.log(1024));
    return parseFloat((sizeInBytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i];
  };

  return (
    <div className={`${error ? 'error-form-file' : ''}`}>
      <div className="uk-flex uk-flex-middle">
        <button className="uk-button--l uk-button-cancel" onClick={onClickFileSelect} type="button">ファイルを選択</button>
        {description && <p className="uk-margin-small-left">{description}</p>}
      </div>
      <input
        type="file"
        className="uk-hidden"
        {...rest}
        name={name}
        ref={(e) => {
          registerRef(e);
          hiddenInputRef.current = e;
        }}
        multiple
        onChange={handleAddFiles}
      />
      <div>
        {fields.map((field: any, index: number) => (
          <div className={`uk-flex uk-flex-middle ${index == 0 ? 'uk-margin-small-top' : ''}`}>
            <p key={field.id}>{`${field.file.name} (${formatFileSize(field.file.size)})`}</p>
            <span className="padding-4" data-uk-icon={`icon: close; ratio: 0.9`} onClick={() => remove(index)}></span>
          </div>
        ))}
      </div>
    </div>
  );
};