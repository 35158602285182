import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function ApplyMailSent() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const onClickSend = () => {};

  return (
    <div className="uk-flex uk-flex-column uk-flex-middle">
      <div className="sub-header">
        <p className="uk-text-large uk-text-bold">DRM-DB 申込</p>
      </div>
      <div className="uk-flex uk-width-3-4 uk-margin-top">
        <div className="step-content">
          <ul className="step">
            <li>メールアドレスの入力</li>
            <li className="is-current">メールアドレスの送信完了</li>
            <li>お客様情報の登録</li>
            <li>登録内容の確認</li>
            <li>お申し込みの完了</li>
          </ul>
        </div>
      </div>

      <div className="uk-flex uk-flex-column uk-flex-middle uk-margin-top">
        <p className="uk-text-medium">
          ご指定のアドレス宛に申込フォームURLを送らせて頂きました。
          <br />
          <br />
          <span className="uk-color-red">数分以内に</span>
          メールが届かない場合は、迷惑メールフォルダに振り分けられている可能性があります。ご確認ください。
          <br />
          <br />
          またメールアドレスが間違っている可能性がある場合は再度フォームにてお問い合わせ下さい。
        </p>
      </div>
    </div>
  );
}
