import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext, useWatch, RegisterOptions } from 'react-hook-form';
import * as Config from '@/config'
import * as Common from '@/utils/common'

interface AttributeProps {
  attribute: any;
  rules?: RegisterOptions;
  className?: string;
  disabled?: boolean;
  hidden?: boolean;
  value?: string;
  min?: string;
  customOption?: string;  // Checkboxの場合にOption名をカスタマイズするためのパラメタ
  customTitle?: string;
  type?: string;
}

export const AttributeRow = (props: AttributeProps) => {
  // const required = attr.attribute_setting_values.find((setting: any) => setting.attribute_setting_code === Config.ATTRIBUTE_SETTING_IS_REQUIRED);
  const { attribute, rules, customTitle } = props;
  return (
    <tr key={attribute.id}>
      <td>
        {customTitle ? customTitle : attribute.name}
        {rules?.required && <span className="required">必須</span>}
      </td>
      <td><AttributeComponent {...props} /></td>
    </tr>
  )
};

export const AttributeComponent = ({ attribute, rules, className, disabled, hidden, value, min, customOption, type }: AttributeProps) => {
  if (hidden) {
    return InputText(attribute, { rules, type: 'hidden', className, value })
  }
  switch (attribute.attribute_kind_code) {
    case Config.ATTRIBUTE_KINDS_CODE_TEXT_FIELD:
      return InputText(attribute, { rules, className, disabled, value, min, type: type ?? 'text' })
    case Config.ATTRIBUTE_KINDS_CODE_NUMBER_INT:
    case Config.ATTRIBUTE_KINDS_CODE_NUMBER_DECIMAL:
      return InputText(attribute, { rules, className, disabled, value, min, type: type ?? 'number' })
    case Config.ATTRIBUTE_KINDS_CODE_TEXT_AREA:
      return InputTextArea(attribute, { rules, className, disabled, value })
    case Config.ATTRIBUTE_KINDS_CODE_SELECT_BOX:
    case Config.ATTRIBUTE_KINDS_CODE_CHECK_BOX:
    case Config.ATTRIBUTE_KINDS_CODE_RADIO_BUTTON:
      const [parentName, filterName] = hasRelation(attribute);
      if (parentName && filterName)
        return HasRelation(attribute, parentName, filterName, { rules, className, disabled, value, customOption })
      else
        return NoneRelation(attribute, { rules, className, disabled, value, customOption })
    case Config.ATTRIBUTE_KINDS_CODE_DATE:
      return InputText(attribute, { rules, type: type ?? 'date', className, disabled, value, min })
  }
  return <></>;
}

// TODO: 共通化, props整理
export const InputText = (attribute: any, props?: any) => {
  const { register } = useFormContext();
  const { formState: { errors }, } = useFormContext<{ attributes: Array<any> }>(); // TODO: 書き方
  const name = `attributes.${attribute.id}`;
  const error = errors.attributes?.[attribute.id]?.message;

  return (
    <>
      <input
        key={name}
        type={props?.type}
        className={`${props?.className} ${error ? "error-form" : ""}`}
        {...register(name, props?.rules)}
        value={props?.value}
        min={props?.min}
        disabled={props?.disabled}
      />
    </>
  )
}

export const InputTextArea = (attribute: any, props?: any) => {
  const { register } = useFormContext();
  const { formState: { errors }, } = useFormContext<{ attributes: Array<any> }>();
  const name = `attributes.${attribute.id}`;
  const error = errors.attributes?.[attribute.id]?.message;

  return (
    <>
      <textarea
        className={`${props?.className} ${error ? "error-form" : ""}`}
        disabled={props?.disabled}
        {...register(name, props?.rules)}
      />
    </>
  )
}

export const hasRelation = (attribute: any) => {
  for (const setting of attribute.attribute_setting_values) {
    if (setting.attribute_setting_code === Config.ATTRIBUTE_SETTING_PARENT_ATTRIBUTE_ID)
      return [`attributes.${setting.value}`, 'parent_attribute_options_id'];
    if (setting.attribute_setting_code === Config.ATTRIBUTE_SETTING_IS_AREA_RELATION)
      return ['area_id', 'parent_area_id'];
    if (setting.attribute_setting_code === Config.ATTRIBUTE_SETTING_IS_BLOCK_RELATION)
      return ['block_id', 'parent_block_id'];
  }
  return [null, null];
}

export const HasRelation = (attribute: any, parentName: string, filterName: string, props?: any) => {
  const { control, register, setValue, resetField, formState: { isDirty } } = useFormContext();
  const name = `attributes.${attribute.id}`;

  const selectedParentAttribute = useWatch({
    control,
    name: parentName,
  });

  const selectedAttribute = useWatch({
    control,
    name: name,
  });

  const filteredAttributes = useMemo(() => {
    // console.log(`useMemo\nname::${name}\nparentName::${parentName}\nselectedParentAttribute::${selectedParentAttribute}`)
    attribute.attribute_options.sort((a: any, b: any) => a.code - b.code);
    if (selectedParentAttribute) {
      return attribute.attribute_options.filter((option: any) => {
        return option.attribute_option_parent_values.some((parentValue: any) => {
          // TODO: area_idとblock_idはidなので統一性がない(postと入力値取得時にcodeとvalueに変換？)
          return parentValue[filterName] === parseInt(selectedParentAttribute.split(Config.SPLIT_CODE)[0])
        });
      });
    }
    return attribute.attribute_options;
  }, [selectedParentAttribute]);

  useEffect(() => {
    if (isDirty) {
      // console.log(`useEffect\nname::${name}\nparentName::${parentName}\nselectedParentAttribute::${selectedParentAttribute}`)
      setValue(name, '');
    }
  }, [filteredAttributes]);

  switch (attribute.attribute_kind_code) {
    case Config.ATTRIBUTE_KINDS_CODE_SELECT_BOX:
      return SelectBox(attribute, filteredAttributes, props);
    case Config.ATTRIBUTE_KINDS_CODE_CHECK_BOX:
      return CheckBox(attribute, filteredAttributes, props);
    case Config.ATTRIBUTE_KINDS_CODE_RADIO_BUTTON:
      return RadioButton(attribute, filteredAttributes, props);
    default:
      return <></>;
  }
}

export const NoneRelation = (attribute: any, props?: any) => {
  const name = `attributes.${attribute.id}`;
  switch (attribute.attribute_kind_code) {
    case Config.ATTRIBUTE_KINDS_CODE_SELECT_BOX:
      return SelectBox(attribute, attribute.attribute_options, props);
    case Config.ATTRIBUTE_KINDS_CODE_CHECK_BOX:
      return CheckBox(attribute, attribute.attribute_options, props);
    case Config.ATTRIBUTE_KINDS_CODE_RADIO_BUTTON:
      return RadioButton(attribute, attribute.attribute_options, props);
    default:
      return <></>;
  }
}

export const SelectBox = (attribute: any, options: any, props?: any) => {
  const { register } = useFormContext();
  const { formState: { errors }, } = useFormContext<{ attributes: Array<any> }>();
  const name = `attributes.${attribute.id}`;
  const error = errors.attributes?.[attribute.id]?.message;

  return (
    <>
      <select className={`${props?.className} ${error ? "error-form" : ""}`}
        defaultValue={""}
        key={name}
        disabled={props?.disabled}
        {...register(name, props?.rules)}>
        <option value="">選択してください</option>
        {options.map((o: any) => (
          <option key={`option_${attribute.id}_${o.id}`} value={Common.inputAttributeOptionValue(o)}>{o.code + ': ' + o.option}</option>
        ))}
      </select>
    </>
  )
}

export const CheckBox = (attribute: any, options: any, props?: any) => {
  const { register } = useFormContext();
  const { formState: { errors }, } = useFormContext<{ attributes: Array<any> }>();
  const name = `attributes.${attribute.id}`;
  const error = errors.attributes?.[attribute.id]?.message;
  const className: string = props?.className ?? 'uk-grid-small uk-grid';
  const errorClassName = error ? (className.includes('uk-grid') ? 'error-form-grid' : 'error-form') : '';

  return (
    <div className={`${className} ${errorClassName}`}>
      {options.map((o: any) => (
        <div>
          <label>
            <input
              type="checkbox"
              disabled={props?.disabled}
              {...register(name, props?.rules)}
              value={Common.inputAttributeOptionValue(o)} />
            {props?.customOption ?? o.option}
          </label>
        </div>
      ))}
    </div>
  )
}

export const RadioButton = (attribute: any, options: any, props?: any) => {
  const { register } = useFormContext();
  const { formState: { errors }, } = useFormContext<{ attributes: Array<any> }>();
  const name = `attributes.${attribute.id}`;
  const error = errors.attributes?.[attribute.id]?.message;
  const className: string = props?.className ?? 'uk-grid-small uk-grid';
  const errorClassName = error ? (className.includes('uk-grid') ? 'error-form-grid' : 'error-form') : '';

  return (
    <div className={`${className} ${errorClassName}`}>
      {options.map((o: any) => (
        <div>
          <label>
            <input
              type="radio"
              disabled={props?.disabled}
              {...register(name, props?.rules)}
              value={Common.inputAttributeOptionValue(o)} />
            {o.option}
          </label>
        </div>
      ))}
    </div>
  )
}