import * as Config from "@/config";
import * as Common from "@/utils/common";
import {Controller, UseFormReturn} from "react-hook-form";
import question from "@/assets/images/question.svg";
import React from "react";
import {ApplyForm} from "@/stores/applyForm";

interface RenderDBInfoProps {
  getAttribute: (columnName: string) => any;
  methods: UseFormReturn<ApplyForm, any, undefined>;
  showDBType: boolean;
  showShapeFile: boolean;
  isResearchInstitution: boolean;
  showVersion: boolean;
  isMultipleSelectDBType: boolean;
}

export const RenderDBInfo = (props: RenderDBInfoProps) => {
  const {
    getAttribute,
    methods,
    showDBType,
    showShapeFile,
    isResearchInstitution,
    showVersion,
    isMultipleSelectDBType
  } = props
  const dbTypeAttribute = getAttribute(Config.ATTRIBUTE_COLUMN_NAME_DB_TYPE);
  const dbEditionAttribute = getAttribute(
    Config.ATTRIBUTE_COLUMN_NAME_DB_EDITION
  );
  const {
    formState: {errors},
  } = methods;
  const dbTypeError = errors.attributes?.[dbTypeAttribute.id]?.message;
  const dbEditionError = errors.attributes?.[dbEditionAttribute.id]?.message;
  const dbTypeValue0 = Common.inputAttributeOptionValue(
    dbTypeAttribute.attribute_options[0]
  );
  const dbTypeValue1 = Common.inputAttributeOptionValue(
    dbTypeAttribute.attribute_options[1]
  );
  const dbTypeValue2 = Common.inputAttributeOptionValue(
    dbTypeAttribute.attribute_options[2]
  );
  const dbTypeValue3 = Common.inputAttributeOptionValue(
    dbTypeAttribute.attribute_options[3]
  );

  const formType = isMultipleSelectDBType ? "checkbox" : "radio";

  const handleInputChange = (field: any, currentValue: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isMultipleSelectDBType) {
      const newValue = e.target.checked
        ? [...(field.value || []), currentValue]
        : (field.value || []).filter((v: string) => v !== currentValue);
      field.onChange(newValue);
    } else {
      field.onChange(currentValue);
    }
  };

  const isChecked = (field: any, value: string) => {
    if (isMultipleSelectDBType) {
      return field.value?.includes(value);
    } else {
      return field.value === value;
    }
  };

  return (
    <table className="form-tbl">
      <tbody>
      {showDBType && (
        <tr>
          <td>
            DB種類<span className="required">必須</span>
          </td>
          <td>
            <Controller
              {...methods.register(`attributes.${dbTypeAttribute.id}`, {
                required: Config.MESSAGE_NO_E08("DB種類"),
              })}
              render={({field}) => (
                <div className={dbTypeError ? "error-form" : ""}>
                  <label>
                    <input
                      type={formType}
                      {...field}
                      value={dbTypeValue0}
                      checked={isChecked(field, dbTypeValue0)}
                      onChange={handleInputChange(field, dbTypeValue0)}
                    />
                    DRM-DB 標準パッケージ
                  </label>
                  <label
                    className="uk-margin-small-top"
                    data-uk-tooltip="title: 版：A版（日本測地系）文字コード：SJIS<br>期間：03版（現時点でご用意できる最新年度の3月版となります）<br>
                        内容：その1<br>
                        ※「渋滞統計システム」とは？<br>
                        日本道路交通情報センターが販売するシステムに必要なパッケージデータとなります。<br>
                        利用できる「A版」の作成年は、日本道路交通情報センターにお問い合わせください。; pos: top-left"
                  >
                    <input
                      type={formType}
                      {...field}
                      value={dbTypeValue1}
                      checked={isChecked(field, dbTypeValue1)}
                      onChange={handleInputChange(field, dbTypeValue1)}
                    />
                    渋滞統計システム用
                    <img
                      src={question}
                      className="uk-margin-small-left cursor-pointer"
                      onClick={() =>
                        window.open(
                          "https://www.jartic.or.jp/s/service/forcorporation/forcorporation03/",
                          "_blank"
                        )
                      }
                    />
                  </label>
                  {showShapeFile && (
                    <label
                      className="uk-margin-small-top"
                      data-uk-tooltip="title: 版：DRMシェープファイル形式（世界測地系）<br>
                          期間：03版（現時点でご用意できる最新年度の3月版となります）<br>
                          内容：属性情報はA版と同じ
                          ; pos: top-left"
                    >
                      <input
                        type={formType}
                        {...field}
                        value={dbTypeValue2}
                        checked={isChecked(field, dbTypeValue2)}
                        onChange={handleInputChange(field, dbTypeValue2)}
                      />
                      DRMシェープファイル
                    </label>
                  )}
                  <label
                    className="uk-margin-small-top"
                    data-uk-tooltip="title: 上記以外のデータを希望の場合は、Wordファイルをダウンロードいただき、希望事項をご記入のうえ、下段ファイル添付フォームよりアップロードください。
                        ; pos: top-left"
                  >
                    <input
                      type={formType}
                      {...field}
                      value={dbTypeValue3}
                      checked={isChecked(field, dbTypeValue3)}
                      onChange={handleInputChange(field, dbTypeValue3)}
                    />
                    その他
                    <a
                      href={require(`../../assets/files/${isResearchInstitution ? "University01.docx" : "Format01.docx"}`)}
                      download="DRM-DBのその他申込.docx"
                      className="uk-margin-left"
                    >
                      <img
                        src={require("../../assets/images/word.png")}
                        className="width-32"
                      />
                      DRM-DBのその他申込
                    </a>
                  </label>
                </div>
              )}
            />
          </td>
        </tr>
      )}
      {showVersion && (
        <tr>
          <td>
            版<span className="required">必須</span>
          </td>
          <td>
            <div className={dbEditionError ? "error-form" : ""}>
              <label
                data-uk-tooltip="title: 版：A版　現標準（日本測地系）<br>
                    期間：03版（現時点でご用意できる最新年度の3月版となります）<br>
                    内容：その1～その3データ、標高データ付き<br>
                    ; pos: top-left"
              >
                <input
                  type="checkbox"
                  {...methods.register(
                    `attributes.${dbEditionAttribute.id}`,
                    {
                      required: Config.MESSAGE_NO_E08("版"),
                    }
                  )}
                  value={Common.inputAttributeOptionValue(
                    dbEditionAttribute.attribute_options[0]
                  )}
                />
                【DRM-DBのA版】SJIS/ビューワあり
              </label>
              <label
                className="uk-margin-small-top"
                data-uk-tooltip="title: 版：BW版　DRM21対応（世界測地系）<br>
                    期間：03版（現時点でご用意できる最新年度の3月版となります）<br>
                    内容：標高データ付き<br>
                    ; pos: top-left"
              >
                <input
                  type="checkbox"
                  {...methods.register(
                    `attributes.${dbEditionAttribute.id}`,
                    {
                      required: Config.MESSAGE_NO_E08("版"),
                    }
                  )}
                  value={Common.inputAttributeOptionValue(
                    dbEditionAttribute.attribute_options[1]
                  )}
                />
                【DRM-DBのBW版】世界測地系/ビューワあり
              </label>
            </div>
          </td>
        </tr>
      )}
      </tbody>
    </table>
  );
}