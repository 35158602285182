export const API_URL = process.env.REACT_APP_API_URL as string;
export const TITLE = process.env.REACT_APP_TITLE as string;
export const HEADER_ENV = process.env.REACT_APP_HEADER_ENV as string;
export const BASENAME = process.env.REACT_APP_BASENAME as string;
export const BASE_URL = process.env.REACT_APP_BASE_URL as string;
export const PROJECT_ID = 2;
export const PROJECT_CATEGORY_ID = 3;
export const AREA_ID = 100;
export const BLOCK_ID = 100;
export const SPLIT_CODE = "@@@"; // selectの区切り文字
export const DATE_FORMAT = "YYYY-MM-DD";

export const APPLY_FORM_URL = `${BASE_URL}${BASENAME}/apply`;

export const EMAIL_ADDRESS_PARAM = 'email_address';

// attribute_settings
export const ATTRIBUTE_SETTING_IS_REQUIRED = '0010';
export const ATTRIBUTE_SETTING_LIMIT = '0020';
export const ATTRIBUTE_SETTING_DEFAULT_CODE = '0030';
export const ATTRIBUTE_SETTING_DEFAULT_VALUE = '0031';
export const ATTRIBUTE_SETTING_PARENT_ATTRIBUTE_ID = '0040';
export const ATTRIBUTE_SETTING_IS_AREA_RELATION = '0050';
export const ATTRIBUTE_SETTING_IS_BLOCK_RELATION = '0060';
export const ATTRIBUTE_SETTING_LIST_WIDTH = '0070';

// attribute_kinds_code
export const ATTRIBUTE_KINDS_CODE_TEXT_FIELD = '01';
export const ATTRIBUTE_KINDS_CODE_TEXT_AREA = '02';
export const ATTRIBUTE_KINDS_CODE_SELECT_BOX = '03';
export const ATTRIBUTE_KINDS_CODE_CHECK_BOX = '04';
export const ATTRIBUTE_KINDS_CODE_RADIO_BUTTON = '05';
export const ATTRIBUTE_KINDS_CODE_NUMBER_INT = '06';
export const ATTRIBUTE_KINDS_CODE_NUMBER_DECIMAL = '07';
export const ATTRIBUTE_KINDS_CODE_DATE = '08';

// file_attribute_code
export const FILE_ATTRIBUTE_CODE_OTHER = '20';  // その他
export const FILE_ATTRIBUTE_CODE_USE = '30';  // 利用申請書
export const FILE_ATTRIBUTE_CODE_APPROVE = '31';  // 承諾書
export const FILE_ATTRIBUTE_CODE_COPY = '32';  // 複製依頼書

export const ATTRIBUTE_COLUMN_NAME_CONTRAT = 'contrat';
export const ATTRIBUTE_COLUMN_NAME_APPLY_TYPE = 'apply_type';
export const ATTRIBUTE_COLUMN_NAME_INQUIRER_TYPE = 'inquirer_type';
export const ATTRIBUTE_COLUMN_NAME_GROUP_TYPE = 'group_type';
export const ATTRIBUTE_COLUMN_NAME_GROUP_NAME = 'group_name';
export const ATTRIBUTE_COLUMN_NAME_DEPARTMENT = 'department';
export const ATTRIBUTE_COLUMN_NAME_SECTION = 'section';
export const ATTRIBUTE_COLUMN_NAME_UNIT = 'unit';
export const ATTRIBUTE_COLUMN_NAME_POSITION = 'position';
export const ATTRIBUTE_COLUMN_NAME_LAST_NAME = 'last_name';
export const ATTRIBUTE_COLUMN_NAME_FIRST_NAME = 'first_name';
export const ATTRIBUTE_COLUMN_NAME_LAST_NAME_KANA = 'last_name_kana';
export const ATTRIBUTE_COLUMN_NAME_FIRST_NAME_KANA = 'first_name_kana';
export const ATTRIBUTE_COLUMN_NAME_PHONE_NUMBER = 'phone_number';
export const ATTRIBUTE_COLUMN_NAME_ZIP_CODE = 'zip_code';
export const ATTRIBUTE_COLUMN_NAME_PREF = 'pref';
export const ATTRIBUTE_COLUMN_NAME_CITY = 'city';
export const ATTRIBUTE_COLUMN_NAME_HOUSE_NUMBER = 'house_number';
export const ATTRIBUTE_COLUMN_NAME_BUILDING_NAME = 'building_name';
export const ATTRIBUTE_COLUMN_NAME_EMAIL_ADDRESS = 'email_address';
export const ATTRIBUTE_COLUMN_NAME_DB_TYPE = 'db_type';
export const ATTRIBUTE_COLUMN_NAME_DB_EDITION = 'db_edition';
export const ATTRIBUTE_COLUMN_NAME_PURPOSE = 'purpose';
export const ATTRIBUTE_COLUMN_NAME_PRESENTATION_SCHEDULE = 'presentation_schedule';
export const ATTRIBUTE_COLUMN_NAME_PRESENTATION_DESTINATION = 'presentation_destination';
export const ATTRIBUTE_COLUMN_NAME_PRESENTATION_TYPE = 'presentation_type';
export const ATTRIBUTE_COLUMN_NAME_DELIVERY_DATE = 'delivery_date';
export const ATTRIBUTE_COLUMN_NAME_DESTINATION = 'destination';
export const ATTRIBUTE_COLUMN_NAME_DESTINATION_GROUP_NAME = 'destination_group_name';
export const ATTRIBUTE_COLUMN_NAME_DESTINATION_DEPARTMENT = 'destination_department';
export const ATTRIBUTE_COLUMN_NAME_DESTINATION_LAST_NAME = 'destination_last_name';
export const ATTRIBUTE_COLUMN_NAME_DESTINATION_FIRST_NAME = 'destination_first_name';
export const ATTRIBUTE_COLUMN_NAME_DESTINATION_PHONE_NUMBER = 'destination_phone_number';
export const ATTRIBUTE_COLUMN_NAME_DESTINATION_ZIP_CODE = 'destination_zip_code';
export const ATTRIBUTE_COLUMN_NAME_DESTINATION_PREF = 'destination_pref';
export const ATTRIBUTE_COLUMN_NAME_DESTINATION_CITY = 'destination_city';
export const ATTRIBUTE_COLUMN_NAME_DESTINATION_HOUSE_NUMBER = 'destination_house_number';
export const ATTRIBUTE_COLUMN_NAME_DESTINATION_BUILDING_NAME = 'destination_building_name';
export const ATTRIBUTE_COLUMN_NAME_BILLING = 'billing';
export const ATTRIBUTE_COLUMN_NAME_BILLING_GROUP_NAME = 'billing_group_name';
export const ATTRIBUTE_COLUMN_NAME_BILLING_DEPARTMENT = 'billing_department';
export const ATTRIBUTE_COLUMN_NAME_BILLING_LAST_NAME = 'billing_last_name';
export const ATTRIBUTE_COLUMN_NAME_BILLING_FIRST_NAME = 'billing_first_name';
export const ATTRIBUTE_COLUMN_NAME_BILLING_PHONE_NUMBER = 'billing_phone_number';
export const ATTRIBUTE_COLUMN_NAME_BILLING_ZIP_CODE = 'billing_zip_code';
export const ATTRIBUTE_COLUMN_NAME_BILLING_PREF_NAME = 'billing_pref_name';
export const ATTRIBUTE_COLUMN_NAME_BILLING_CITY_NAME = 'billing_city_name';
export const ATTRIBUTE_COLUMN_NAME_BILLING_HOUSE_NUMBER = 'billing_house_number';
export const ATTRIBUTE_COLUMN_NAME_BILLING_BUILDING_NAME = 'billing_building_name';
export const ATTRIBUTE_COLUMN_NAME_SPECIAL_NOTES = 'special_notes';
export const ATTRIBUTE_COLUMN_NAME_PERSONAL_INFORMATION_HANDLING = 'personal_information_handling';

export const ATTRIBUTE_COLUMN_NAMES = [
  ATTRIBUTE_COLUMN_NAME_CONTRAT,
  ATTRIBUTE_COLUMN_NAME_APPLY_TYPE,
  ATTRIBUTE_COLUMN_NAME_INQUIRER_TYPE,
  ATTRIBUTE_COLUMN_NAME_GROUP_TYPE,
  ATTRIBUTE_COLUMN_NAME_GROUP_NAME,
  ATTRIBUTE_COLUMN_NAME_DEPARTMENT,
  ATTRIBUTE_COLUMN_NAME_SECTION,
  ATTRIBUTE_COLUMN_NAME_UNIT,
  ATTRIBUTE_COLUMN_NAME_POSITION,
  ATTRIBUTE_COLUMN_NAME_LAST_NAME,
  ATTRIBUTE_COLUMN_NAME_FIRST_NAME,
  ATTRIBUTE_COLUMN_NAME_LAST_NAME_KANA,
  ATTRIBUTE_COLUMN_NAME_FIRST_NAME_KANA,
  ATTRIBUTE_COLUMN_NAME_PHONE_NUMBER,
  ATTRIBUTE_COLUMN_NAME_ZIP_CODE,
  ATTRIBUTE_COLUMN_NAME_PREF,
  ATTRIBUTE_COLUMN_NAME_CITY,
  ATTRIBUTE_COLUMN_NAME_HOUSE_NUMBER,
  ATTRIBUTE_COLUMN_NAME_BUILDING_NAME,
  ATTRIBUTE_COLUMN_NAME_EMAIL_ADDRESS,
  ATTRIBUTE_COLUMN_NAME_DB_TYPE,
  ATTRIBUTE_COLUMN_NAME_DB_EDITION,
  ATTRIBUTE_COLUMN_NAME_PURPOSE,
  ATTRIBUTE_COLUMN_NAME_PRESENTATION_SCHEDULE,
  ATTRIBUTE_COLUMN_NAME_PRESENTATION_DESTINATION,
  ATTRIBUTE_COLUMN_NAME_PRESENTATION_TYPE,
  ATTRIBUTE_COLUMN_NAME_DELIVERY_DATE,
  ATTRIBUTE_COLUMN_NAME_DESTINATION,
  ATTRIBUTE_COLUMN_NAME_DESTINATION_GROUP_NAME,
  ATTRIBUTE_COLUMN_NAME_DESTINATION_DEPARTMENT,
  ATTRIBUTE_COLUMN_NAME_DESTINATION_LAST_NAME,
  ATTRIBUTE_COLUMN_NAME_DESTINATION_FIRST_NAME,
  ATTRIBUTE_COLUMN_NAME_DESTINATION_PHONE_NUMBER,
  ATTRIBUTE_COLUMN_NAME_DESTINATION_ZIP_CODE,
  ATTRIBUTE_COLUMN_NAME_DESTINATION_PREF,
  ATTRIBUTE_COLUMN_NAME_DESTINATION_CITY,
  ATTRIBUTE_COLUMN_NAME_DESTINATION_HOUSE_NUMBER,
  ATTRIBUTE_COLUMN_NAME_DESTINATION_BUILDING_NAME,
  ATTRIBUTE_COLUMN_NAME_BILLING,
  ATTRIBUTE_COLUMN_NAME_BILLING_GROUP_NAME,
  ATTRIBUTE_COLUMN_NAME_BILLING_DEPARTMENT,
  ATTRIBUTE_COLUMN_NAME_BILLING_LAST_NAME,
  ATTRIBUTE_COLUMN_NAME_BILLING_FIRST_NAME,
  ATTRIBUTE_COLUMN_NAME_BILLING_PHONE_NUMBER,
  ATTRIBUTE_COLUMN_NAME_BILLING_ZIP_CODE,
  ATTRIBUTE_COLUMN_NAME_BILLING_PREF_NAME,
  ATTRIBUTE_COLUMN_NAME_BILLING_CITY_NAME,
  ATTRIBUTE_COLUMN_NAME_BILLING_HOUSE_NUMBER,
  ATTRIBUTE_COLUMN_NAME_BILLING_BUILDING_NAME,
  ATTRIBUTE_COLUMN_NAME_SPECIAL_NOTES,
  ATTRIBUTE_COLUMN_NAME_PERSONAL_INFORMATION_HANDLING,
];

export const ATTRIBUTE_CODES = [ATTRIBUTE_KINDS_CODE_SELECT_BOX, ATTRIBUTE_KINDS_CODE_CHECK_BOX, ATTRIBUTE_KINDS_CODE_RADIO_BUTTON];
export const ATTRIBUTE_MULTIPLE_CODES = [ATTRIBUTE_KINDS_CODE_CHECK_BOX];

export const MESSAGE_NO_E08 = ( name: string ) => {
  return `「${name}」は必須項目です。`;
}
export const MESSAGE_NO_E35 = ( name: string, num: number ) => {
  return `「${name}」は${num}文字以内で入力してください。`;
}
export const MESSAGE_NO_E09 = "郵便番号が存在しません。";
export const MESSAGE_NO_E10 = "申込エリアが重複しています。";
export const MESSAGE_NO_E11 = "合計容量が10ＭＢを超えています。";
export const MESSAGE_NO_E21 = "入力されたメールアドレスは利用できません。";
export const MESSAGE_NO_E36 = "「備考」は200字以内で入力してください。";
export const MESSAGE_NO_E37 = "データの保存に失敗しました。再度実行してください。";
export const MESSAGE_NO_E39 = "データの取得に失敗しました。再度実行してください。";
export const MESSAGE_NO_E41 = "コメントを送信してよろしいでしょうか？";
export const MESSAGE_NO_E66 = "コメントを入力してください。";