import { axios } from '@/lib/axios';
import {PostComment} from "@/components/Ticket/MessageInputBox";

export const getTicketUrl = ( pathname: string ) => {
  return axios.get('/api/v1/dataTickets/getTicketUrl', {
    params: { pathname }
  })
}

export const postComment = ( postData: PostComment ) => {
  return axios.post('/api/v1/dataTickets/postComment', postData)
}