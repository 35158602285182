import * as Config from "@/config";
import { ApplyForm } from "@/stores/applyForm";
import * as Common from "@/utils/common";

export const inputAttributeOptionValue = (attributeOption: any) => {
  return createAttributeOptionValue(
    attributeOption.id,
    attributeOption.code,
    attributeOption.option
  );
};
// TODO: 値の持ち方検討→selectにはoption_idで持っておいてget/postの際にcode_valueで検索
export const createAttributeOptionValue = (
  attribute_option_id: any,
  attribute_code: any,
  attribute_value: any
) => {
  return (
    attribute_option_id +
    Config.SPLIT_CODE +
    attribute_code +
    Config.SPLIT_CODE +
    attribute_value
  );
};

export const inputAttributeToPostParam = (
  attributes: Array<any>,
  data: any,
  dataMap: Map<string, any> | undefined = undefined
): any => {
  const inputAttributes = {} as any;
  if (!data.attributes) return inputAttributes;
  for (const attribute of attributes) {
    const inputValue = data.attributes[attribute.id];
    if (!inputValue || inputValue.length === 0) continue;
    const dataAttributeValue = dataMap?.get(attribute.id) || undefined;
    if (Config.ATTRIBUTE_CODES.includes(attribute.attribute_kind_code)) {
      const values: any[] = [];
      if (Array.isArray(inputValue)) {
        for (const check of inputValue) {
          const [option_id, code, value] = check.split(Config.SPLIT_CODE);
          const setValue = {
            attribute_code: code,
            attribute_value: value,
          } as any;
          if (dataAttributeValue) {
            const existsData = existsDataAttributeValue(
              code,
              value,
              dataAttributeValue
            );
            if (existsData) setValue["id"] = existsData.id;
          }
          values.push(setValue);
        }
        inputAttributes[attribute.id] = values;
      } else if (
        typeof inputValue === "string" &&
        inputValue.includes(Config.SPLIT_CODE)
      ) {
        const [option_id, code, value] = inputValue.split(Config.SPLIT_CODE);
        const setValue = {
          attribute_code: code,
          attribute_value: value,
        } as any;
        if (dataAttributeValue) {
          const existsData = existsDataAttributeValue(
            code,
            value,
            dataAttributeValue
          );
          if (existsData) setValue["id"] = existsData.id;
        }
        inputAttributes[attribute.id] = setValue;
      } else {
        inputAttributes[attribute.id] = { attribute_value: inputValue };
        if (dataAttributeValue) {
          inputAttributes[attribute.id]["id"] = dataAttributeValue.id;
        }
      }
    } else {
      inputAttributes[attribute.id] = { attribute_value: inputValue };
      if (dataAttributeValue) {
        inputAttributes[attribute.id]["id"] = dataAttributeValue.id;
      }
    }
  }
  return inputAttributes;
};

export const filesFromApplyForm = (formData: ApplyForm) => {
  return Array.of(
    formData.files0.map((item) => {
      return { ...item, file_attribute_code: Config.FILE_ATTRIBUTE_CODE_OTHER };
    }),
    formData.files1.map((item) => {
      return { ...item, file_attribute_code: Config.FILE_ATTRIBUTE_CODE_USE };
    }),
    formData.files2.map((item) => {
      return {
        ...item,
        file_attribute_code: Config.FILE_ATTRIBUTE_CODE_APPROVE,
      };
    }),
    formData.files3.map((item) => {
      return { ...item, file_attribute_code: Config.FILE_ATTRIBUTE_CODE_COPY };
    })
  )
    .flat(1)
    .filter((item) => !!item);
};

export const filesToPostParam = (formData: ApplyForm): any => {
  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const files = filesFromApplyForm(formData);

  return Promise.all(
    files.map(async (item) => {
      const base64 = await toBase64(item.file);
      return {
        file_name: item.file.name,
        file_content: base64,
        file_attribute_code: item.file_attribute_code,
      };
    })
  );
};

export const areasToPostParam = (formData: ApplyForm): any => {
  return formData.areas
    .filter((area) => area.sales_product_id)
    .map((area) => {
      return {
        sales_product_id: parseInt(area.sales_product_id),
      };
    });
};

export const existsDataAttributeValue = (
  attribute_code: string,
  attribute_value: string,
  dataAttributeValue: any
): any => {
  if (Array.isArray(dataAttributeValue)) {
    for (const check of dataAttributeValue) {
      if (
        attribute_code === check.attribute_code &&
        attribute_value === check.attribute_value
      )
        return check;
    }
  } else {
    if (
      attribute_code === dataAttributeValue.attribute_code &&
      attribute_value === dataAttributeValue.attribute_value
    )
      return dataAttributeValue;
  }
  return undefined;
};

export const convertToKeyMap = (
  datas: Array<any>,
  key: string
): Map<string, any> => {
  const map = new Map();
  for (const data of datas) {
    if (map.has(data[key])) {
      const value = map.get(data[key]);
      if (Array.isArray(value)) {
        value.push(data);
      } else {
        const array = [value, data];
        map.set(data[key], array);
      }
    } else {
      map.set(data[key], data);
    }
  }
  return map;
};

export const getNestedValue = (data: any): any => {
  if (typeof data === "object")
    return getNestedValue(data[Object.keys(data)[0]]);
  else return data;
};

/* DBから取得したエリア一覧（ツリー含む）からエリアを探す */
export const getArea = (areas: any[], id: number): any | null => {
  for (const area of areas) {
    const result = getAreaById(area, id);
    if (result) {
      return result;
    }
  }
  return null;
};

/* ツリーのエリアデータから子エリアを探す */
export const getAreaById = (area: any, id: number): any | null => {
  if (area.id == id) {
    return area;
  }
  if (area.children) {
    for (const child of area.children) {
      const result = getAreaById(child, id);
      if (result) {
        return result;
      }
    }
  }
  return null;
};

/* 申込エリアの名リストを取得する */
export const getSelectedAreaList = (applyAreas: any[], areas: any[]) => {
  return applyAreas
    .map((item) => ({
      ...getArea(areas, item.sales_product_id),
      area_type: item.area_type,
    }))
    .filter((item) => item && item.area_type)
    .sort((a, b) => a.code - b.code);
};

/* react-hook-formのフォーム値から実際のvalueを取得する */
export const extractValue = (composed: string | Array<string>): string => {
  if (Array.isArray(composed)) {
    return composed
      .map((value) => {
        return extractValue(value);
      })
      .join("\n");
  }
  const values = composed.split(Config.SPLIT_CODE);
  if (values.length == 3) {
    return values[2];
  } else {
    return composed;
  }
};

export const formatPrice = (number: number) => {
  if (isNaN(number) || !number) {
    number = 0;
  }
  const formatter = new Intl.NumberFormat("ja-JP");
  return formatter.format(number);
};

export const isInvalidEmail = (value: string) => {
  const regex =
    /^(?=.{1,254}$)(?=.{1,64}@)[0-9A-Za-z._%+-]+(\.[0-9A-Za-z]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)+$/;

  if (!regex.test(value)) {
    return "メールアドレスが不正値です。";
  }
  return true;
};

export const maxSizeSum = (value: any) => {
  let totalSize = 0;
  for (let i = 0; i < value.length; i++) {
    totalSize += value[i]?.file?.size || 0;
  }
  return totalSize <= 1024 * 1024 * 10 || Config.MESSAGE_NO_E11;
};
