import React, {useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

type ConfirmModalProps = {
  text: string;
  confirmButtonText: string;
  className?: string;
  isShow: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
};

function ConfirmModal(props: ConfirmModalProps) {
  const modalRoot = document.getElementById("modal-root");

  useEffect(() => {
    
  }, [props.isShow]);

  const onClickConfirm = () => {
    if (props.onConfirm) {
      props.onConfirm();
    }
  };

  const onClickCancel = () => {
    if (props.onCancel) {
      props.onCancel();
    }
  };
  
  return (
    modalRoot && props.isShow ?
      createPortal(
        <div className="modal">
          <div className="modal-content">
            <div className="modal-text">{props.text}</div>
            <div className="modal-buttons">
              {props.onCancel && <button className="uk-button--m uk-button-cancel" onClick={onClickCancel}>キャンセル</button>}
              <button className="uk-button--m uk-button-refer uk-margin-left" onClick={onClickConfirm}>{props.confirmButtonText}</button>
            </div>
          </div>
        </div>, modalRoot
      )
      : null
  );
}

export default ConfirmModal;