import { axios } from '@/lib/axios';
import { PROJECT_CATEGORY_ID } from '@/config';

export const sendEmailAddress = (email_address: string, form_url: string) => {
  return axios.post(`/api/v1/users/sendEmailAddress`, {
    project_category_id: PROJECT_CATEGORY_ID,
    email_address,
    form_url,
  });
}

export const getAreaList = () => {
  return axios.get(`/api/v1/areas/getAreaList`, {
    params: {
      project_category_id: PROJECT_CATEGORY_ID,
    },
  });
}

export const getAttributeByColumnName = (attribute_list: string[]) => {
  return axios.get(`/api/v1/attributes/getAttributeByColumnName`, {
    params: {
      project_category_id: PROJECT_CATEGORY_ID,
      attribute_list,
    },
  });
}

export const getBusinessDay = (days: number, base_date: string) => {
  return axios.get(`/api/v1/drmHolidays/getBusinessDay`, {
    params: {
      days,
      base_date,
    },
  });
}

export const postApply = (data: any) => {
  return axios.post(`/api/v1/datas/postApply`, data);
}

export const getAddressByZipCode = (zipcode: string) => {
  return axios.get('/api/v1/proxy/getAddress', {
    params: {
      zipcode,
    },
  })
}

export const checkExpiryDate = (email_address: any) => {
  return axios.get(`/api/v1/users/checkExpiryDate`, {
    params: {
      project_category_id: PROJECT_CATEGORY_ID,
      email_address 
    },
  });
}