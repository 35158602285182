import React, { useState, useEffect, useMemo, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useForm,
  useFieldArray,
  useWatch,
  FormProvider,
} from "react-hook-form";
import { useLoadingStore } from "@/stores/loading";
import { useApplyFormStore, ApplyForm } from "@/stores/applyForm";
import * as Config from "@/config";
import * as Common from "@/utils/common";
import { ApplyAreaForm } from "@/components/ApplyAreaForm";
import { AttributeRow, AttributeComponent } from "@/components/Form/Attributes";
import * as API from "@/api";
import moment from "moment";
import { FileSelect } from "@/components/Form/FileSelect";
import ConfirmModal from "@/components/ConfirmModal";
import {RenderDBInfo} from "@/components/Apply/RenderDBInfo";
import {isArray} from "@craco/craco/dist/lib/utils";

type Address = {
  address1: string;
  address2: string;
  address3: string;
  kana1: string;
  kana2: string;
  kana3: string;
  prefcode: string;
  zipcode: string;
};

export default function Apply() {
  const [searchParams, setSearchParams] = useSearchParams();
  const emailAddress = searchParams.get("email_address");

  const navigate = useNavigate();
  const setLoading = useLoadingStore((state) => state.setLoading);
  const applyFormStore = useApplyFormStore();
  const methods = useForm<ApplyForm>({
    defaultValues: applyFormStore.applyForm ?? {
      attributes: [],
      areas: [
        {
          id: "1",
          checked: false,
          type: null,
          sales_product_id: null,
          sales_product_id2: null,
        },
      ],
      files0: [],
      files1: [],
      files2: [],
      files3: [],
    },
  });

  const attributes = useRef(applyFormStore.attributes);
  const areas = useRef(applyFormStore.areas);
  const [businessDay, setBusinessDay] = useState<string>(
    applyFormStore.businessDay
  );
  const [showEstimateWindow, setShowEstimateWindow] = useState(false);
  const [departmentTitle, setDepartmentTitle] = useState<string>("部 署");
  const [positionTitle, setPositionTitle] = useState<string>("役 職");
  const [nameTitle, setNameTitle] = useState<string>("氏 名");
  const [submitted, setSubmitted] = useState(false);
  const [viewLoaded, setViewLoaded] = useState(false);
  const [isShowExpiredModal, setIsShowExpiredModal] = useState(false);

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "areas",
  });

  const getAttribute = (columnName: string) => {
    return attributes.current.find(
      (item: any) => item.column_name === columnName
    );
  };

  const getAttributeById = (id: number) => {
    return attributes.current.find((item: any) => item.id === id);
  };

  const watchApplyAreasList = useWatch({
    control: methods.control,
    name: "areas",
  });
  const watchApplyType = methods.watch(
    `attributes.${getAttribute(Config.ATTRIBUTE_COLUMN_NAME_APPLY_TYPE)?.id}`
  );
  const watchInquirerType = methods.watch(
    `attributes.${getAttribute(Config.ATTRIBUTE_COLUMN_NAME_INQUIRER_TYPE)?.id}`
  );
  const watchGroupType = methods.watch(
    `attributes.${getAttribute(Config.ATTRIBUTE_COLUMN_NAME_GROUP_TYPE)?.id}`
  );
  const watchDestination = methods.watch(
    `attributes.${getAttribute(Config.ATTRIBUTE_COLUMN_NAME_DESTINATION)?.id}`
  );
  const watchZipCode = methods.watch(
    `attributes.${getAttribute(Config.ATTRIBUTE_COLUMN_NAME_ZIP_CODE)?.id}`
  );
  const watchDBType = methods.watch(
    `attributes.${getAttribute(Config.ATTRIBUTE_COLUMN_NAME_DB_TYPE)?.id}`
  );
  const watchDestinationZipCode = methods.watch(
    `attributes.${
      getAttribute(Config.ATTRIBUTE_COLUMN_NAME_DESTINATION_ZIP_CODE)?.id
    }`
  );
  const watchBilling = methods.watch(
    `attributes.${getAttribute(Config.ATTRIBUTE_COLUMN_NAME_BILLING)?.id}`
  );
  const watchBillingZipCode = methods.watch(
    `attributes.${
      getAttribute(Config.ATTRIBUTE_COLUMN_NAME_BILLING_ZIP_CODE)?.id
    }`
  );

  useEffect(() => {
    setViewLoaded(true);
    if (attributes.current.length == 0) {
      setLoading(true);
      API.checkExpiryDate(emailAddress)
        .then((result) => {
          if (result.data.expired == true) {
            setLoading(false);
            setIsShowExpiredModal(true);
          } else {
            fetchData();
          }
        })
        .catch((error) => {
          fetchData();
        });
    }
  }, []);

  useEffect(() => {
    const { errors } = methods.formState;
    if (submitted == true) {
      if (
        errors.areas ||
        errors.attributes ||
        errors.files0 ||
        errors.files1 ||
        errors.files2 ||
        errors.files3 ||
        isApplyAreaDuplicated
      ) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
      setSubmitted(false);
    }
  }, [methods.formState]);

  const setAddress = (address: Address | null) => {
    if (address == null) {
      methods.setValue(
        `attributes.${getAttribute(Config.ATTRIBUTE_COLUMN_NAME_PREF)?.id}`,
        null
      );
      methods.setValue(
        `attributes.${getAttribute(Config.ATTRIBUTE_COLUMN_NAME_CITY)?.id}`,
        null
      );
      methods.setValue(
        `attributes.${
          getAttribute(Config.ATTRIBUTE_COLUMN_NAME_HOUSE_NUMBER)?.id
        }`,
        null
      );
    } else {
      methods.setValue(
        `attributes.${getAttribute(Config.ATTRIBUTE_COLUMN_NAME_PREF)?.id}`,
        address.address1
      );
      methods.setValue(
        `attributes.${getAttribute(Config.ATTRIBUTE_COLUMN_NAME_CITY)?.id}`,
        address.address2
      );
      methods.setValue(
        `attributes.${
          getAttribute(Config.ATTRIBUTE_COLUMN_NAME_HOUSE_NUMBER)?.id
        }`,
        address.address3
      );
    }
  };

  const setDestinationAddress = (address: Address | null) => {
    if (address == null) {
      methods.setValue(
        `attributes.${
          getAttribute(Config.ATTRIBUTE_COLUMN_NAME_DESTINATION_PREF)?.id
        }`,
        null
      );
      methods.setValue(
        `attributes.${
          getAttribute(Config.ATTRIBUTE_COLUMN_NAME_DESTINATION_CITY)?.id
        }`,
        null
      );
      methods.setValue(
        `attributes.${
          getAttribute(Config.ATTRIBUTE_COLUMN_NAME_DESTINATION_HOUSE_NUMBER)
            ?.id
        }`,
        null
      );
    } else {
      methods.setValue(
        `attributes.${
          getAttribute(Config.ATTRIBUTE_COLUMN_NAME_DESTINATION_PREF)?.id
        }`,
        address.address1
      );
      methods.setValue(
        `attributes.${
          getAttribute(Config.ATTRIBUTE_COLUMN_NAME_DESTINATION_CITY)?.id
        }`,
        address.address2
      );
      methods.setValue(
        `attributes.${
          getAttribute(Config.ATTRIBUTE_COLUMN_NAME_DESTINATION_HOUSE_NUMBER)
            ?.id
        }`,
        address.address3
      );
    }
  };

  const setBillingAddress = (address: Address | null) => {
    if (address == null) {
      methods.setValue(
        `attributes.${
          getAttribute(Config.ATTRIBUTE_COLUMN_NAME_BILLING_PREF_NAME)?.id
        }`,
        null
      );
      methods.setValue(
        `attributes.${
          getAttribute(Config.ATTRIBUTE_COLUMN_NAME_BILLING_CITY_NAME)?.id
        }`,
        null
      );
      methods.setValue(
        `attributes.${
          getAttribute(Config.ATTRIBUTE_COLUMN_NAME_BILLING_HOUSE_NUMBER)?.id
        }`,
        null
      );
    } else {
      methods.setValue(
        `attributes.${
          getAttribute(Config.ATTRIBUTE_COLUMN_NAME_BILLING_PREF_NAME)?.id
        }`,
        address.address1
      );
      methods.setValue(
        `attributes.${
          getAttribute(Config.ATTRIBUTE_COLUMN_NAME_BILLING_CITY_NAME)?.id
        }`,
        address.address2
      );
      methods.setValue(
        `attributes.${
          getAttribute(Config.ATTRIBUTE_COLUMN_NAME_BILLING_HOUSE_NUMBER)?.id
        }`,
        address.address3
      );
    }
  };

  useEffect(() => {
    if (!viewLoaded) {
      return;
    }
    if (typeof watchZipCode === "string" && watchZipCode.length == 7) {
      setLoading(true);
      API.getAddressByZipCode(watchZipCode)
        .then((result) => {
          if (result.data.results.length > 0) {
            setAddress(result.data.results[0]);
          } else {
            setAddress(null);
          }
        })
        .catch((error) => {
          setAddress(null);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [watchZipCode]);

  useEffect(() => {
    if (!viewLoaded) {
      return;
    }
    if (
      typeof watchDestinationZipCode === "string" &&
      watchDestinationZipCode.length == 7
    ) {
      setLoading(true);
      API.getAddressByZipCode(watchDestinationZipCode)
        .then((result) => {
          if (result.data.results.length > 0) {
            setDestinationAddress(result.data.results[0]);
          } else {
            setDestinationAddress(null);
          }
        })
        .catch((error) => {
          setDestinationAddress(null);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [watchDestinationZipCode]);

  useEffect(() => {
    if (!viewLoaded) {
      return;
    }
    if (
      typeof watchBillingZipCode === "string" &&
      watchBillingZipCode.length == 7
    ) {
      setLoading(true);
      API.getAddressByZipCode(watchBillingZipCode)
        .then((result) => {
          if (result.data.results.length > 0) {
            setBillingAddress(result.data.results[0]);
          } else {
            setBillingAddress(null);
          }
        })
        .catch((error) => {
          setBillingAddress(null);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [watchBillingZipCode]);

  useEffect(() => {
    if (watchGroupType) {
      const code = watchGroupType.split(Config.SPLIT_CODE)[1];
      if (code == 7) {
        // 団体区分：「大学等研究機関」
        setDepartmentTitle("大学・研究機関名");
        setPositionTitle("職位");
        setNameTitle("責任者氏名");
      } else {
        setDepartmentTitle("部 署");
        setPositionTitle("役 職");
        setNameTitle("氏 名");
      }
    }
  }, [watchGroupType]);

  //サンプルデータのみかどうか
  const isSampleData = watchApplyType?.split(Config.SPLIT_CODE)[1] == 2;
  //道路管理者かどうか
  const isRoadAdministrator = watchInquirerType?.split(Config.SPLIT_CODE)[1] == 1;
  //大学等研究機関かどうか
  const isResearchInstitution = watchGroupType?.split(Config.SPLIT_CODE)[1] == 7;
  //企業・法人かどうか
  const isCompanyOrCorporation = () => {
    const code = watchGroupType?.split(Config.SPLIT_CODE)[1];
    return code == 4 || code == 6 ;
  }

  // 申込エリアが重複しているかどうか
  const isApplyAreaDuplicated = useMemo(() => {
    let result = false;
    if (watchApplyAreasList) {
      for (let i = 0; i < watchApplyAreasList.length; i++) {
        fields[i].error = null;
        fields[i].error_area_type = null;
        fields[i].duplicate_index = null;
      }
      for (let i = 0; i < watchApplyAreasList.length - 1; i++) {
        for (let j = i + 1; j < watchApplyAreasList.length; j++) {
          const area1 = watchApplyAreasList[i];
          const area2 = watchApplyAreasList[j];
          if (area1.area_type == 1) {
            if (area2.area_type == 1) {
              result = true;
              fields[i].error_area_type = Config.MESSAGE_NO_E10;
              fields[j].error_area_type = Config.MESSAGE_NO_E10;
              fields[i].duplicate_index = j;
              fields[j].duplicate_index = i;
            } else {
              if (area1.duplicate_index == j && area2.duplicate_index == i) {
                fields[i].error_area_type = null;
                fields[j].error_area_type = null;
              }
            }
          }
          if (area1.area_type == 2 || area1.area_type == 4) {
            if (
              area1.area_type == area2.area_type &&
              area1.sales_product_id == area2.sales_product_id &&
              !!area1.sales_product_id
            ) {
              result = true;
              fields[i].error = Config.MESSAGE_NO_E10;
              fields[j].error = Config.MESSAGE_NO_E10;
              fields[i].duplicate_index = j;
              fields[j].duplicate_index = i;
            } else {
              if (area1.duplicate_index == j && area2.duplicate_index == i) {
                fields[i].error = null;
                fields[j].error = null;
              }
            }
          }
          if (area1.area_type == 3) {
            if (
              area1.area_type == area2.area_type &&
              area1.sales_product_id == area2.sales_product_id &&
              area1.sales_product_id2 == area2.sales_product_id2 &&
              !!area1.sales_product_id &&
              !!area1.sales_product_id2
            ) {
              result = true;
              fields[i].error = Config.MESSAGE_NO_E10;
              fields[j].error = Config.MESSAGE_NO_E10;
            } else {
              if (area1.duplicate_index == j && area2.duplicate_index == i) {
                fields[i].error = null;
                fields[j].error = null;
              }
            }
          }
        }
      }
    }
    return result;
  }, [watchApplyAreasList]);

  // 申込エリア表示
  const showApplyArea = useMemo(() => {
    let flag = false;
    if (watchApplyType) {
      const code = watchApplyType.split(Config.SPLIT_CODE)[1];
      flag = code != 2; // 申込区分：「サンプルデータのみ申込」
    }
    if (!flag) {
      methods.setValue(`areas`, [
        {
          id: "1",
          checked: false,
          type: null,
          sales_product_id: null,
          sales_product_id2: null,
        },
      ]);
    }
    return flag;
  }, [watchApplyType]);

  // DB種類表示
  const showDBType = useMemo(() => {
    let flag = false;
    if (watchApplyType) {
      const code = watchApplyType.split(Config.SPLIT_CODE)[1];
      flag = code != 2; // 申込区分：「サンプルデータのみ申込」
    }
    if (!flag) {
      methods.setValue(
        `attributes.${getAttribute(Config.ATTRIBUTE_COLUMN_NAME_DB_TYPE)?.id}`,
        null
      );
    }
    return flag;
  }, [watchApplyType]);

  // 請求先表示
  const showBilling = useMemo(() => {
    let flag = false;
    if (watchApplyType) {
      const code = watchApplyType.split(Config.SPLIT_CODE)[1];
      flag = code != 2 && !isRoadAdministrator; // 申込区分：「サンプルデータのみ申込」
    }
    if (!flag) {
      methods.setValue(
        `attributes.${getAttribute(Config.ATTRIBUTE_COLUMN_NAME_BILLING)?.id}`,
        null
      );
    }
    return flag;
  }, [watchApplyType,watchInquirerType]);

  // 請求先情報表示
  const showBillingInfo = useMemo(() => {
    let flag = false;
    if (watchBilling && showBilling) {
      const code = watchBilling.split(Config.SPLIT_CODE)[1];
      flag = code == 3; // 請求先：「上記以外」
    }
    if (!flag) {
      [
        Config.ATTRIBUTE_COLUMN_NAME_BILLING_GROUP_NAME,
        Config.ATTRIBUTE_COLUMN_NAME_BILLING_DEPARTMENT,
        Config.ATTRIBUTE_COLUMN_NAME_BILLING_LAST_NAME,
        Config.ATTRIBUTE_COLUMN_NAME_BILLING_FIRST_NAME,
        Config.ATTRIBUTE_COLUMN_NAME_BILLING_PHONE_NUMBER,
        Config.ATTRIBUTE_COLUMN_NAME_BILLING_ZIP_CODE,
        Config.ATTRIBUTE_COLUMN_NAME_BILLING_PREF_NAME,
        Config.ATTRIBUTE_COLUMN_NAME_BILLING_CITY_NAME,
        Config.ATTRIBUTE_COLUMN_NAME_BILLING_HOUSE_NUMBER,
        Config.ATTRIBUTE_COLUMN_NAME_BILLING_BUILDING_NAME,
      ].forEach((columnName) => {
        methods.setValue(`attributes.${getAttribute(columnName)?.id}`, null);
      });
    }
    return flag;
  }, [watchBilling, showBilling]);

  // データ送付先情報表示
  const showDestinationInfo = useMemo(() => {
    let flag = false;
    if (watchDestination) {
      const code = watchDestination.split(Config.SPLIT_CODE)[1];
      flag = code == 2; // データ送付先：「上記以外」
    }
    if (!flag) {
      [
        Config.ATTRIBUTE_COLUMN_NAME_DESTINATION_GROUP_NAME,
        Config.ATTRIBUTE_COLUMN_NAME_DESTINATION_DEPARTMENT,
        Config.ATTRIBUTE_COLUMN_NAME_DESTINATION_LAST_NAME,
        Config.ATTRIBUTE_COLUMN_NAME_DESTINATION_FIRST_NAME,
        Config.ATTRIBUTE_COLUMN_NAME_DESTINATION_PHONE_NUMBER,
        Config.ATTRIBUTE_COLUMN_NAME_DESTINATION_ZIP_CODE,
        Config.ATTRIBUTE_COLUMN_NAME_DESTINATION_PREF,
        Config.ATTRIBUTE_COLUMN_NAME_DESTINATION_CITY,
        Config.ATTRIBUTE_COLUMN_NAME_DESTINATION_HOUSE_NUMBER,
        Config.ATTRIBUTE_COLUMN_NAME_DESTINATION_BUILDING_NAME,
      ].forEach((columnName) => {
        methods.setValue(`attributes.${getAttribute(columnName)?.id}`, null);
      });
    }
    return flag;
  }, [watchDestination]);

  // ファイル添付表示
  const showFileAttach = useMemo(() => {
    let flag = false;
    if (watchInquirerType && !isSampleData) {
      const code = watchInquirerType.split(Config.SPLIT_CODE)[1];
      flag = code == 1; // 問合せ者区分：「道路管理者」
    }
    if (!flag) {
      methods.setValue(`files1`, []);
      methods.setValue(`files2`, []);
      methods.setValue(`files3`, []);
    }
    return flag;
  }, [watchInquirerType]);

  const areaOptions = useMemo(() => {
    if (watchInquirerType) {
      const code = watchInquirerType.split(Config.SPLIT_CODE)[1];
      if (code == 1) {
        // 問合せ者区分：「道路管理者」
        return areas.current;
      }
    }
    return areas.current.filter((item: any) => item.code != 3);
  }, [watchInquirerType]);

  // 版表示
  const showVersion = useMemo(() => {
    let flag = false;
    if (watchDBType && watchApplyType) {
      let dbTypeBool:boolean;
      if (isArray(watchDBType)){
        dbTypeBool = watchDBType.some((type:any)=> {
          return type.split(Config.SPLIT_CODE)[1] == 1;
        });
      } else {
        dbTypeBool = watchDBType.split(Config.SPLIT_CODE)[1] == 1
      }
      const applyTypeCode = watchApplyType.split(Config.SPLIT_CODE)[1];
      flag = dbTypeBool && applyTypeCode != 2; // DB種類：「DRM-DB 標準パッケージ」、申込区分：「サンプルデータのみ申込」
    }
    if (!flag) {
      [Config.ATTRIBUTE_COLUMN_NAME_DB_EDITION].forEach((columnName) => {
        methods.setValue(`attributes.${getAttribute(columnName)?.id}`, null);
      });
    }
    return flag;
  }, [watchDBType, watchApplyType]);

  // Shapeファイル表示
  const showShapeFile = useMemo(() => {
    if (watchGroupType) {
      const code = watchGroupType.split(Config.SPLIT_CODE)[1];
      return code != 4 && code != 6 && code != 8; // 団体区分：「企業」「財団法人等」「その他」
    }
    return true;
  }, [watchGroupType]);

  // 研究成果の発表予定表示
  const showPresentation = useMemo(() => {
    let flag = false;
    if (watchGroupType) {
      const code = watchGroupType.split(Config.SPLIT_CODE)[1];
      flag = code == 7; // 団体区分：「大学等研究機関」
    }
    if (!flag) {
      [
        Config.ATTRIBUTE_COLUMN_NAME_PRESENTATION_SCHEDULE,
        Config.ATTRIBUTE_COLUMN_NAME_PRESENTATION_DESTINATION,
        Config.ATTRIBUTE_COLUMN_NAME_PRESENTATION_TYPE,
      ].forEach((columnName) => {
        methods.setValue(`attributes.${getAttribute(columnName)?.id}`, null);
      });
    }
    return flag;
  }, [watchGroupType]);

  //申込エリア追加ボタン表示
  const showAppendApplyAreaContainer = () => {
    return !isResearchInstitution ;
  }

  /* 見積計算する */
  const estimateAmount = useMemo<number>(() => {
    if (watchApplyType) {
      const applyTypeCode = watchApplyType.split(Config.SPLIT_CODE)[1];
      if (applyTypeCode == 2) {
        // 申込区分：「サンプルデータのみ申込」
        return 0;
      }
    }
    if (watchApplyAreasList && watchInquirerType) {
      const inquirerTypeCode = watchInquirerType.split(Config.SPLIT_CODE)[1];
      const selectedAreas = Common.getSelectedAreaList(
        watchApplyAreasList,
        areas.current
      );
      if (inquirerTypeCode == 1 || !watchGroupType) {
        // 問合せ者区分：「道路管理者」
        return 0;
      }
      if (selectedAreas.length == 0) {
        return 0; // 選択されていない場合には次の計算ロジックが不要なので0をreturn
      }
      const groupTypeCode = watchGroupType.split(Config.SPLIT_CODE)[1];
      if (["1", "2", "3"].includes(groupTypeCode)) {
        // 国, 県, 市町村
        return (
          selectedAreas.map((area) => area.amount).reduce((a, b) => a + b, 0) /
          2
        );
      }
      if (groupTypeCode == 7) {
        // 「大学等研究機関」
        // 市区町村のみ無料にする
        let cities = selectedAreas
          .filter((area) => area.area_type == 3)
          .sort((a, b) => b.amount - a.amount);
        if (cities.length > 0) {
          selectedAreas.splice(selectedAreas.indexOf(cities[0]), 1);
        }
        let amounts = selectedAreas
          .filter((item) => item.amount)
          .map((area) => area.amount);
        return amounts.reduce((a, b) => a + b, 0) / 2;
      }
      return selectedAreas
        .map((area) => area.amount)
        .reduce((a, b) => a + b, 0);
    }
    return 0;
  }, [watchApplyAreasList, watchInquirerType, watchGroupType, watchApplyType]);

  const fetchData = () => {
    Promise.all([
      API.getAreaList(),
      API.getAttributeByColumnName(Config.ATTRIBUTE_COLUMN_NAMES),
      API.getBusinessDay(5, moment(Date()).format(Config.DATE_FORMAT)),
    ])
      .then((result) => {
        const { tax_rate, area_list } = result[0].data;
        applyFormStore.setTaxRate(parseFloat(tax_rate));
        applyFormStore.setAreas(area_list);
        applyFormStore.setAttributes(result[1].data.attributes);
        applyFormStore.setBusinessDay(result[2].data.business_day);
        areas.current = area_list;
        attributes.current = result[1].data.attributes;
        setBusinessDay(result[2].data.business_day);
        initValues();
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const initValues = () => {
    const emailAddressAttribute = getAttribute(
      Config.ATTRIBUTE_COLUMN_NAME_EMAIL_ADDRESS
    );
    methods.setValue(`attributes.${emailAddressAttribute?.id}`, emailAddress);
  };

  const onClickApplyAreaDelete = () => {
    const checkedIndexs = watchApplyAreasList
      .map((item, index) => (item.checked ? index : -1))
      .filter((item: any) => item !== -1)
      .sort((a, b) => b - a);
    for (const index of checkedIndexs) {
      remove(index);
    }
  };

  const onSubmit = (data: any) => {
    setSubmitted(true);
    methods.handleSubmit(submit)(data);
  };

  const submit = (data: any) => {
    if (isApplyAreaDuplicated) {
      return;
    }
    if(isRoadAdministrator || isResearchInstitution || isSampleData){
      applyFormStore.setShowQuotationOrderText(false);
    }
    applyFormStore.setApplyForm(data);
    applyFormStore.setEstimateAmount(estimateAmount);
    navigate("/apply_confirm");
  };

  const renderPersonalInformationHandling = () => {
    const {
      formState: { errors },
    } = methods;
    const attr = getAttribute(
      Config.ATTRIBUTE_COLUMN_NAME_PERSONAL_INFORMATION_HANDLING
    );
    const error = errors.attributes?.[attr.id]?.message;
    return (
      <tr>
        <td>
          個人情報の取扱い<span className="required">必須</span>
        </td>
        <td>
          <p>
            下記の内容をご確認いただき、「同意する」にチェックをして内容を送信して下さい。
          </p>
          <div className="agree-box">
            <p className="title">個人情報の取扱いについて</p>
            <p>
              （一財）日本デジタル道路地図協会（以下「当協会」といいます）は、以下のとおり個人情報の保護を推進致します。
            </p>
            <p className="subtitle">個人情報の管理</p>
            <p>
              当協会は、お客さまの個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、セキュリティシステムの維持・管理体制の整備等の必要な措置を講じ、安全対策を実施し個人情報の厳重な管理を行ないます。
            </p>
            <p className="subtitle">個人情報の利用目的</p>
            <p>
              お客さまからお預かりした個人情報は、当協会からのご連絡や業務のご案内やご質問に対する回答として、電子メールや資料のご送付に利用いたします。
            </p>
            <p className="subtitle">個人情報の第三者への開示・提供の禁止</p>
            <p>
              当協会は、お客さまよりお預かりした個人情報を適切に管理し、次のいずれかに該当する場合を除き、個人情報を第三者に開示いたしません。
            </p>
            <p>・お客さまの同意がある場合</p>
            <p>
              ・お客さまが希望されるサービスを行なうために当社が業務を委託する業者に対して開示する場合
            </p>
            <p>・法令に基づき開示することが必要である場合</p>
            <p className="subtitle">個人情報の安全対策</p>
            <p>
              当協会は、個人情報の正確性及び安全性確保のために、セキュリティに万全の対策を講じています。
            </p>
            <p>法令、規範の遵守と見直し</p>
            <p>
              当協会は、保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善に努めます。
            </p>
            <p className="subtitle">お問い合せ</p>
            <p>
              当協会の個人情報の取扱に関するお問い合せは下記までご連絡ください。
            </p>
            <p>（一財）日本デジタル道路地図協会</p>
            <p>〒102-0093</p>
            <p>東京都千代田区平河町1丁目3番13号平河町フロントビル5階</p>
            <p>TEL:03-3222-7990　FAX:03-3222-7991</p>
          </div>
          <div className="uk-flex uk-flex-middle">
            <label>
              <AttributeComponent
                attribute={getAttribute(
                  Config.ATTRIBUTE_COLUMN_NAME_PERSONAL_INFORMATION_HANDLING
                )}
                rules={{ required: Config.MESSAGE_NO_E08("個人情報の取扱い") }}
                customOption="個人情報の取扱いについて同意する"
              />{" "}
            </label>
          </div>
        </td>
      </tr>
    );
  };

  const renderError = () => {
    const { errors } = methods.formState;
    let list: any[] = [];
    const areaErrors = () => {
      let result = [];
      if (errors.areas) {
        const areas = errors.areas as (any | undefined)[];
        result = areas
          .filter((item) => !!item)
          .map((item) => Object.values(item))
          .flat(1)
          .map((item: any) => item.message);
      }
      if (isApplyAreaDuplicated) {
        result.push(Config.MESSAGE_NO_E10);
      }
      return result;
    };
    const fileErrors = () => {
      return [
        errors.files0?.root?.message,
        errors.files1?.root?.message,
        errors.files2?.root?.message,
        errors.files3?.root?.message,
      ].filter((item) => !!item);
    };
    if (errors.attributes) {
      const attributes = errors.attributes as (any | undefined)[];
      const array = attributes
        .map((item, index) => {
          const attr = getAttributeById(index);
          return {
            ...item,
            id: index,
            index: Config.ATTRIBUTE_COLUMN_NAMES.indexOf(attr?.column_name),
          };
        })
        .filter((item) => !!item.message)
        .sort((a, b) => {
          return a.index - b.index;
        });
      let attributesErrors: any[] = [];
      let areaErrorsAdded = false;
      let fileErrorsAdded = false;
      array.forEach((item) => {
        if (
          item.index >
            Config.ATTRIBUTE_COLUMN_NAMES.indexOf(
              Config.ATTRIBUTE_COLUMN_NAME_DB_TYPE
            ) &&
          !areaErrorsAdded
        ) {
          attributesErrors = attributesErrors.concat(areaErrors());
          areaErrorsAdded = true;
        }
        if (
          item.index >
            Config.ATTRIBUTE_COLUMN_NAMES.indexOf(
              Config.ATTRIBUTE_COLUMN_NAME_BILLING_HOUSE_NUMBER
            ) &&
          !fileErrorsAdded
        ) {
          attributesErrors = attributesErrors.concat(fileErrors());
          fileErrorsAdded = true;
        }
        attributesErrors.push(item.message);
      });
      if (!areaErrorsAdded) {
        attributesErrors = attributesErrors.concat(areaErrors());
      }
      if (!fileErrorsAdded) {
        attributesErrors = attributesErrors.concat(fileErrors());
      }
      list = list.concat(attributesErrors);
    } else {
      list = list.concat(areaErrors());
      list = list.concat(fileErrors());
    }
    list = list.filter((item) => !!item);

    if (list.length > 0) {
      return (
        <>
          <div className="error-box uk-width-1-1">入力エラーがあります</div>
          <div className="uk-margin-small-top uk-margin-bottom">
            {list.map((item: any) => {
              return <div className="error uk-margin-left">・{item}</div>;
            })}
          </div>
        </>
      );
    }
  };

  return (
    <div className="uk-position-relative uk-margin-large-bottom">
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          {attributes.current.length > 0 && (
            <div className="uk-flex uk-flex-column uk-flex-middle">
              <div className="sub-header">
                <p className="uk-text-large uk-text-bold uk-margin-remove">
                  DRM-DB　申込情報入力画面
                </p>
              </div>
              <div className="uk-flex uk-width-3-4 uk-margin-top">
                <div className="step-content">
                  <ul className="step">
                    <li>メールアドレスの入力</li>
                    <li>メールアドレスの送信完了</li>
                    <li className="is-current">お客様情報の登録</li>
                    <li>登録内容の確認</li>
                    <li>お申し込みの完了</li>
                  </ul>
                </div>
              </div>
              <div className="uk-width-3-4 uk-margin-top">
                {renderError()}
                <div className="uk-text-medium">▼申込者情報</div>
                <table className="form-tbl">
                  <tbody>
                    <AttributeRow
                      attribute={getAttribute(
                        Config.ATTRIBUTE_COLUMN_NAME_CONTRAT
                      )}
                      rules={{ required: Config.MESSAGE_NO_E08("新規/継続") }}
                    />
                    <AttributeRow
                      attribute={getAttribute(
                        Config.ATTRIBUTE_COLUMN_NAME_APPLY_TYPE
                      )}
                      rules={{ required: Config.MESSAGE_NO_E08("申込区分") }}
                    />
                    <AttributeRow
                      attribute={getAttribute(
                        Config.ATTRIBUTE_COLUMN_NAME_INQUIRER_TYPE
                      )}
                      rules={{
                        required: Config.MESSAGE_NO_E08("問合せ者区分"),
                      }}
                    />
                    <AttributeRow
                      attribute={getAttribute(
                        Config.ATTRIBUTE_COLUMN_NAME_GROUP_TYPE
                      )}
                      rules={{ required: Config.MESSAGE_NO_E08("団体区分") }}
                    />
                    <AttributeRow
                      attribute={getAttribute(
                        Config.ATTRIBUTE_COLUMN_NAME_GROUP_NAME
                      )}
                      rules={{
                        required: Config.MESSAGE_NO_E08("法人・団体名"),
                        maxLength: {
                          value: 30,
                          message: Config.MESSAGE_NO_E35("法人・団体名", 30),
                        },
                      }}
                      className="uk-width-1-1"
                    />
                    <AttributeRow
                      attribute={getAttribute(
                        Config.ATTRIBUTE_COLUMN_NAME_DEPARTMENT
                      )}
                      customTitle={departmentTitle}
                      rules={{
                        required: Config.MESSAGE_NO_E08(departmentTitle),
                        maxLength: {
                          value: 64,
                          message: Config.MESSAGE_NO_E35(departmentTitle, 64),
                        },
                      }}
                      className="uk-width-1-1"
                    />
                    <AttributeRow
                      attribute={getAttribute(
                        Config.ATTRIBUTE_COLUMN_NAME_SECTION
                      )}
                      rules={{
                        maxLength: {
                          value: 64,
                          message: Config.MESSAGE_NO_E35("課名", 64),
                        },
                      }}
                      className="uk-width-1-1"
                    />
                    <AttributeRow
                      attribute={getAttribute(
                        Config.ATTRIBUTE_COLUMN_NAME_UNIT
                      )}
                      rules={{
                        maxLength: {
                          value: 64,
                          message: Config.MESSAGE_NO_E35("室名または係名", 64),
                        },
                      }}
                      className="uk-width-1-1"
                    />
                    <AttributeRow
                      attribute={getAttribute(
                        Config.ATTRIBUTE_COLUMN_NAME_POSITION
                      )}
                      customTitle={positionTitle}
                      rules={{
                        required: Config.MESSAGE_NO_E08(positionTitle),
                        maxLength: {
                          value: 64,
                          message: Config.MESSAGE_NO_E35(positionTitle, 64),
                        },
                      }}
                      className="uk-width-1-1"
                    />
                    <tr>
                      <td>
                        {nameTitle}
                        <span className="required">必須</span>
                      </td>
                      <td>
                        <div className="uk-grid-small" data-uk-grid>
                          <div className="uk-flex">
                            <p className="width-32">姓</p>
                            <AttributeComponent
                              attribute={getAttribute(
                                Config.ATTRIBUTE_COLUMN_NAME_LAST_NAME
                              )}
                              rules={{
                                required: Config.MESSAGE_NO_E08("姓"),
                                maxLength: {
                                  value: 60,
                                  message: Config.MESSAGE_NO_E35(
                                    positionTitle,
                                    60
                                  ),
                                },
                              }}
                            />
                          </div>
                          <div className="uk-flex">
                            <p className="width-32">名</p>
                            <AttributeComponent
                              attribute={getAttribute(
                                Config.ATTRIBUTE_COLUMN_NAME_FIRST_NAME
                              )}
                              rules={{
                                required: Config.MESSAGE_NO_E08("名"),
                                maxLength: {
                                  value: 60,
                                  message: Config.MESSAGE_NO_E35(
                                    positionTitle,
                                    60
                                  ),
                                },
                              }}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        ふりがな<span className="required">必須</span>
                      </td>
                      <td>
                        <div className="uk-grid-small" data-uk-grid>
                          <div className="uk-flex">
                            <p className="width-32">せい</p>
                            <AttributeComponent
                              attribute={getAttribute(
                                Config.ATTRIBUTE_COLUMN_NAME_LAST_NAME_KANA
                              )}
                              rules={{
                                required: Config.MESSAGE_NO_E08("せい"),
                                maxLength: {
                                  value: 60,
                                  message: Config.MESSAGE_NO_E35(
                                    positionTitle,
                                    60
                                  ),
                                },
                              }}
                            />
                          </div>
                          <div className="uk-flex">
                            <p className="width-32">めい</p>
                            <AttributeComponent
                              attribute={getAttribute(
                                Config.ATTRIBUTE_COLUMN_NAME_FIRST_NAME_KANA
                              )}
                              rules={{
                                required: Config.MESSAGE_NO_E08("めい"),
                                maxLength: {
                                  value: 60,
                                  message: Config.MESSAGE_NO_E35(
                                    positionTitle,
                                    60
                                  ),
                                },
                              }}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        電話番号<span className="required">必須</span>
                      </td>
                      <td>
                        <div className="uk-flex">
                          <AttributeComponent
                            type="tel"
                            attribute={getAttribute(
                              Config.ATTRIBUTE_COLUMN_NAME_PHONE_NUMBER
                            )}
                            rules={{
                              required: Config.MESSAGE_NO_E08("電話番号"),
                            }}
                          />
                          <p className="uk-margin-left">例）0312345678</p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        住 所<span className="required">必須</span>
                      </td>
                      <td>
                        <div className="uk-flex">
                          <p className="width-110">郵便番号</p>
                          <AttributeComponent
                            type="text"
                            attribute={getAttribute(
                              Config.ATTRIBUTE_COLUMN_NAME_ZIP_CODE
                            )}
                            rules={{
                              required: Config.MESSAGE_NO_E08("郵便番号"),
                            }}
                          />
                          <p className="uk-margin-left">例）1000001</p>
                        </div>
                        <div className="uk-flex uk-margin-small-top">
                          <p className="width-110">都道府県</p>
                          <AttributeComponent
                            attribute={getAttribute(
                              Config.ATTRIBUTE_COLUMN_NAME_PREF
                            )}
                            rules={{
                              required: Config.MESSAGE_NO_E08("都道府県"),
                            }}
                            disabled
                          />
                        </div>
                        <div className="uk-flex uk-margin-small-top">
                          <p className="width-110">市区町村</p>
                          <AttributeComponent
                            attribute={getAttribute(
                              Config.ATTRIBUTE_COLUMN_NAME_CITY
                            )}
                            rules={{
                              required: Config.MESSAGE_NO_E08("市区町村"),
                            }}
                            className="uk-width-expand"
                            disabled
                          />
                        </div>
                        <div className="uk-flex uk-margin-small-top">
                          <p className="width-110">町名番地</p>
                          <AttributeComponent
                            attribute={getAttribute(
                              Config.ATTRIBUTE_COLUMN_NAME_HOUSE_NUMBER
                            )}
                            rules={{
                              required: Config.MESSAGE_NO_E08("町名番地"),
                              maxLength: {
                                value: 128,
                                message: Config.MESSAGE_NO_E35("町名番地", 500),
                              },
                            }}
                            className="uk-width-expand"
                          />
                        </div>
                        <div className="uk-flex uk-margin-small-top">
                          <p className="width-110">建物名・部屋番号</p>
                          <AttributeComponent
                            attribute={getAttribute(
                              Config.ATTRIBUTE_COLUMN_NAME_BUILDING_NAME
                            )}
                            rules={{
                              maxLength: {
                                value: 128,
                                message: Config.MESSAGE_NO_E35(
                                  "建物名・部屋番号",
                                  500
                                ),
                              },
                            }}
                            className="uk-width-expand"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>メールアドレス</td>
                      <td>
                        {emailAddress}
                        <AttributeComponent
                          attribute={getAttribute(
                            Config.ATTRIBUTE_COLUMN_NAME_EMAIL_ADDRESS
                          )}
                          hidden
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="uk-width-3-4 uk-margin-top">
                <div className="uk-text-medium">▼申込DB情報</div>
                <RenderDBInfo
                  getAttribute={getAttribute}
                  methods={methods}
                  showDBType={showDBType}
                  showShapeFile={showShapeFile}
                  isResearchInstitution={isResearchInstitution}
                  showVersion={showVersion}
                  isMultipleSelectDBType={isRoadAdministrator || isResearchInstitution}
                />
                {showApplyArea && fields.length > 1 && (
                  <div className="uk-flex uk-flex-middle uk-margin-small-top">
                    <div className="uk-width-small">
                      <button
                        className="uk-button--l uk-button-refer"
                        onClick={onClickApplyAreaDelete}
                        type="button"
                        disabled={
                          watchApplyAreasList.filter(
                            (item: any) => item.checked
                          ).length == 0
                        }
                      >
                        選択エリアを削除
                      </button>
                    </div>
                    <p className="uk-margin-remove">
                      申込エリアを削除したい場合は左列のチェックボックス（複数選択可）にチェックを入れ削除ボタンを押して下さい。
                    </p>
                  </div>
                )}
                {showApplyArea && !isRoadAdministrator && !isCompanyOrCorporation() && (
                  <div
                    className={`uk-flex uk-flex-right ${
                      fields.length > 1 ? "margin-top-5" : "uk-margin-small-top"
                    }`}
                  >
                    <a
                      className="uk-text-small uk-color-red underline"
                      href="https://www.drm.jp/use/university/"
                      target="_blank"
                    >
                      ※大学・研究機関の無償貸与は1市町村　または東京23区部を原則とします。
                    </a>
                  </div>
                )}
                {showApplyArea && (
                  <table className="form-tbl margin-top-5">
                    {fields.map((item: any, index: number) => {
                      return (
                        <ApplyAreaForm
                          applyArea={item}
                          index={index}
                          length={fields.length}
                          areas={areaOptions}
                          append={append}
                          showAppendApplyAreaContainer={showAppendApplyAreaContainer()}
                        />
                      );
                    })}
                  </table>
                )}
                <table className="form-tbl uk-margin-small-top">
                  <tbody>
                    <tr>
                      <td>
                        利用目的（用途）<span className="required">必須</span>
                      </td>
                      <td>
                        <AttributeComponent
                          attribute={getAttribute(
                            Config.ATTRIBUTE_COLUMN_NAME_PURPOSE
                          )}
                          rules={{
                            required: Config.MESSAGE_NO_E08("利用目的"),
                            maxLength: {
                              value: 500,
                              message: Config.MESSAGE_NO_E35("利用目的", 500),
                            },
                          }}
                          className="uk-width-1-1"
                        />
                      </td>
                    </tr>
                    {showPresentation && (
                      <tr>
                        <td>
                          研究成果の発表予定
                          <span className="required">必須</span>
                        </td>
                        <td>
                          <div className="uk-flex uk-flex-middle">
                            <p className="width-60">予定時期</p>
                            <AttributeComponent
                              attribute={getAttribute(
                                Config.ATTRIBUTE_COLUMN_NAME_PRESENTATION_SCHEDULE
                              )}
                              rules={{
                                required: Config.MESSAGE_NO_E08("予定時期"),
                              }}
                              type="month"
                            />
                            <p className="uk-margin-small-left">頃</p>
                          </div>
                          <div className="uk-flex uk-margin-small-top">
                            <p className="width-60">発表先</p>
                            <AttributeComponent
                              attribute={getAttribute(
                                Config.ATTRIBUTE_COLUMN_NAME_PRESENTATION_DESTINATION
                              )}
                              rules={{
                                required: Config.MESSAGE_NO_E08("発表先"),
                              }}
                            />
                          </div>
                          <div className="uk-flex uk-margin-small-top">
                            <p className="width-60">発表形式</p>
                            <AttributeComponent
                              attribute={getAttribute(
                                Config.ATTRIBUTE_COLUMN_NAME_PRESENTATION_TYPE
                              )}
                              rules={{
                                required: Config.MESSAGE_NO_E08("発表形式"),
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>
                        納品希望日<span className="required">必須</span>
                      </td>
                      <td>
                        <div className="uk-flex uk-flex-middle">
                          <div></div>
                          <AttributeComponent
                            attribute={getAttribute(
                              Config.ATTRIBUTE_COLUMN_NAME_DELIVERY_DATE
                            )}
                            min={businessDay}
                            rules={{
                              required: Config.MESSAGE_NO_E08("納品希望日"),
                            }}
                            className="uk-margin-right"
                          />
                          <p>頃まで</p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        データ送付先<span className="required">必須</span>
                      </td>
                      <td>
                        <AttributeComponent
                          attribute={getAttribute(
                            Config.ATTRIBUTE_COLUMN_NAME_DESTINATION
                          )}
                          rules={{
                            required: Config.MESSAGE_NO_E08("データ送付先"),
                          }}
                          className=""
                        />
                      </td>
                    </tr>
                    {showDestinationInfo && (
                      <tr>
                        <td>
                          送付先情報<span className="required">必須</span>
                        </td>
                        <td>
                          <div className="uk-flex">
                            <p className="width-110">宛先名称</p>
                            <div
                              className="uk-grid uk-grid-small uk-width-expand"
                              data-uk-grid
                            >
                              <div className="uk-flex">
                                <p className="width-85">法人・団体名</p>
                                <AttributeComponent
                                  attribute={getAttribute(
                                    Config.ATTRIBUTE_COLUMN_NAME_DESTINATION_GROUP_NAME
                                  )}
                                  rules={{
                                    required:
                                      Config.MESSAGE_NO_E08(
                                        "送付先 > 法人・団体名"
                                      ),
                                    maxLength: {
                                      value: 30,
                                      message: Config.MESSAGE_NO_E35(
                                        "送付先 > 法人・団体名",
                                        30
                                      ),
                                    },
                                  }}
                                />
                              </div>
                              <div className="uk-flex">
                                <p className="width-85">部署名・所属</p>
                                <AttributeComponent
                                  attribute={getAttribute(
                                    Config.ATTRIBUTE_COLUMN_NAME_DESTINATION_DEPARTMENT
                                  )}
                                  rules={{
                                    required:
                                      Config.MESSAGE_NO_E08(
                                        "送付先 > 部署名・所属"
                                      ),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="uk-flex uk-margin-small-top">
                            <p className="width-110">氏名</p>
                            <div
                              className="uk-grid uk-grid-small uk-width-expand"
                              data-uk-grid
                            >
                              <div className="uk-flex">
                                <p className="width-85">姓</p>
                                <AttributeComponent
                                  attribute={getAttribute(
                                    Config.ATTRIBUTE_COLUMN_NAME_DESTINATION_LAST_NAME
                                  )}
                                  rules={{
                                    required:
                                      Config.MESSAGE_NO_E08("送付先 > 姓"),
                                  }}
                                />
                              </div>
                              <div className="uk-flex">
                                <p className="width-85">名</p>
                                <AttributeComponent
                                  attribute={getAttribute(
                                    Config.ATTRIBUTE_COLUMN_NAME_DESTINATION_FIRST_NAME
                                  )}
                                  rules={{
                                    required:
                                      Config.MESSAGE_NO_E08("送付先 > 名"),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="uk-flex uk-margin-small-top">
                            <p className="width-110">電話番号</p>
                            <AttributeComponent
                              type="tel"
                              attribute={getAttribute(
                                Config.ATTRIBUTE_COLUMN_NAME_DESTINATION_PHONE_NUMBER
                              )}
                              rules={{
                                required:
                                  Config.MESSAGE_NO_E08("送付先 > 電話番号"),
                              }}
                            />
                            <p className="uk-margin-left">例）0312345678</p>
                          </div>
                          <div className="uk-flex uk-margin-small-top">
                            <p className="width-110">郵便番号</p>
                            <AttributeComponent
                              attribute={getAttribute(
                                Config.ATTRIBUTE_COLUMN_NAME_DESTINATION_ZIP_CODE
                              )}
                              rules={{
                                required:
                                  Config.MESSAGE_NO_E08("送付先 > 郵便番号"),
                              }}
                            />
                            <p className="uk-margin-left">例）1000001</p>
                          </div>
                          <div className="uk-flex uk-margin-small-top">
                            <p className="width-110">都道府県</p>
                            <AttributeComponent
                              attribute={getAttribute(
                                Config.ATTRIBUTE_COLUMN_NAME_DESTINATION_PREF
                              )}
                              rules={{
                                required:
                                  Config.MESSAGE_NO_E08("送付先 > 都道府県"),
                              }}
                              disabled
                            />
                          </div>
                          <div className="uk-flex uk-margin-small-top">
                            <p className="width-110">市区町村</p>
                            <AttributeComponent
                              attribute={getAttribute(
                                Config.ATTRIBUTE_COLUMN_NAME_DESTINATION_CITY
                              )}
                              rules={{
                                required:
                                  Config.MESSAGE_NO_E08("送付先 > 市区町村"),
                              }}
                              className="uk-width-expand"
                              disabled
                            />
                          </div>
                          <div className="uk-flex uk-margin-small-top">
                            <p className="width-110">町名番地</p>
                            <AttributeComponent
                              attribute={getAttribute(
                                Config.ATTRIBUTE_COLUMN_NAME_DESTINATION_HOUSE_NUMBER
                              )}
                              rules={{
                                required:
                                  Config.MESSAGE_NO_E08("送付先 > 町名番地"),
                                maxLength: {
                                  value: 128,
                                  message: Config.MESSAGE_NO_E35(
                                    "送付先 > 町名番地",
                                    128
                                  ),
                                },
                              }}
                              className="uk-width-expand"
                            />
                          </div>
                          <div className="uk-flex uk-margin-small-top">
                            <p className="width-110">建物名・部屋番号</p>
                            <AttributeComponent
                              attribute={getAttribute(
                                Config.ATTRIBUTE_COLUMN_NAME_DESTINATION_BUILDING_NAME
                              )}
                              rules={{
                                maxLength: {
                                  value: 128,
                                  message: Config.MESSAGE_NO_E35(
                                    "送付先 > 建物名・部屋番号",
                                    128
                                  ),
                                },
                              }}
                              className="uk-width-expand"
                            />
                          </div>
                        </td>
                      </tr>
                    )}
                    {showBilling && (
                      <tr>
                        <td>
                          請求先<span className="required">必須</span>
                        </td>
                        <td>
                          <AttributeComponent
                            attribute={getAttribute(
                              Config.ATTRIBUTE_COLUMN_NAME_BILLING
                            )}
                            rules={{
                              required: Config.MESSAGE_NO_E08("請求先"),
                            }}
                            className=""
                          />
                        </td>
                      </tr>
                    )}
                    {showBillingInfo && (
                      <tr>
                        <td>
                          請求先情報<span className="required">必須</span>
                        </td>
                        <td>
                          <div className="uk-flex">
                            <p className="width-110">宛先名称</p>
                            <div
                              className="uk-grid uk-grid-small uk-width-expand"
                              data-uk-grid
                            >
                              <div className="uk-flex">
                                <p className="width-85">法人・団体名</p>
                                <AttributeComponent
                                  attribute={getAttribute(
                                    Config.ATTRIBUTE_COLUMN_NAME_BILLING_GROUP_NAME
                                  )}
                                  rules={{
                                    required:
                                      Config.MESSAGE_NO_E08(
                                        "請求先 > 法人・団体名"
                                      ),
                                    maxLength: {
                                      value: 30,
                                      message: Config.MESSAGE_NO_E35(
                                        "請求先 > 法人・団体名",
                                        30
                                      ),
                                    },
                                  }}
                                />
                              </div>
                              <div className="uk-flex">
                                <p className="width-85">部署名・所属</p>
                                <AttributeComponent
                                  attribute={getAttribute(
                                    Config.ATTRIBUTE_COLUMN_NAME_BILLING_DEPARTMENT
                                  )}
                                  rules={{
                                    required:
                                      Config.MESSAGE_NO_E08(
                                        "請求先 > 部署名・所属"
                                      ),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="uk-flex uk-margin-small-top">
                            <p className="width-110">氏名</p>
                            <div
                              className="uk-grid uk-grid-small uk-width-expand"
                              data-uk-grid
                            >
                              <div className="uk-flex">
                                <p className="width-85">姓</p>
                                <AttributeComponent
                                  attribute={getAttribute(
                                    Config.ATTRIBUTE_COLUMN_NAME_BILLING_LAST_NAME
                                  )}
                                  rules={{
                                    required:
                                      Config.MESSAGE_NO_E08("請求先 > 姓"),
                                  }}
                                />
                              </div>
                              <div className="uk-flex">
                                <p className="width-85">名</p>
                                <AttributeComponent
                                  attribute={getAttribute(
                                    Config.ATTRIBUTE_COLUMN_NAME_BILLING_FIRST_NAME
                                  )}
                                  rules={{
                                    required:
                                      Config.MESSAGE_NO_E08("請求先 > 名"),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="uk-flex uk-margin-small-top">
                            <p className="width-110">電話番号</p>
                            <AttributeComponent
                              type="tel"
                              attribute={getAttribute(
                                Config.ATTRIBUTE_COLUMN_NAME_BILLING_PHONE_NUMBER
                              )}
                              rules={{
                                required:
                                  Config.MESSAGE_NO_E08("請求先 > 電話番号"),
                              }}
                            />
                            <p className="uk-margin-left">例）0312345678</p>
                          </div>
                          <div className="uk-flex uk-margin-small-top">
                            <p className="width-110">郵便番号</p>
                            <AttributeComponent
                              attribute={getAttribute(
                                Config.ATTRIBUTE_COLUMN_NAME_BILLING_ZIP_CODE
                              )}
                              rules={{
                                required:
                                  Config.MESSAGE_NO_E08("請求先 > 郵便番号"),
                              }}
                            />
                            <p className="uk-margin-left">例）1000001</p>
                          </div>
                          <div className="uk-flex uk-margin-small-top">
                            <p className="width-110">都道府県</p>
                            <AttributeComponent
                              attribute={getAttribute(
                                Config.ATTRIBUTE_COLUMN_NAME_BILLING_PREF_NAME
                              )}
                              rules={{
                                required:
                                  Config.MESSAGE_NO_E08("請求先 > 都道府県"),
                              }}
                              disabled
                            />
                          </div>
                          <div className="uk-flex uk-margin-small-top">
                            <p className="width-110">市区町村</p>
                            <AttributeComponent
                              attribute={getAttribute(
                                Config.ATTRIBUTE_COLUMN_NAME_BILLING_CITY_NAME
                              )}
                              rules={{
                                required:
                                  Config.MESSAGE_NO_E08("請求先 > 市区町村"),
                              }}
                              className="uk-width-expand"
                              disabled
                            />
                          </div>
                          <div className="uk-flex uk-margin-small-top">
                            <p className="width-110">町名番地</p>
                            <AttributeComponent
                              attribute={getAttribute(
                                Config.ATTRIBUTE_COLUMN_NAME_BILLING_HOUSE_NUMBER
                              )}
                              rules={{
                                required:
                                  Config.MESSAGE_NO_E08("請求先 > 町名番地"),
                                maxLength: {
                                  value: 128,
                                  message: Config.MESSAGE_NO_E35(
                                    "請求先 > 町名番地",
                                    128
                                  ),
                                },
                              }}
                              className="uk-width-expand"
                            />
                          </div>
                          <div className="uk-flex uk-margin-small-top">
                            <p className="width-110">建物名・部屋番号</p>
                            <AttributeComponent
                              attribute={getAttribute(
                                Config.ATTRIBUTE_COLUMN_NAME_BILLING_BUILDING_NAME
                              )}
                              rules={{
                                maxLength: {
                                  value: 128,
                                  message: Config.MESSAGE_NO_E35(
                                    "請求先 > 建物名・部屋番号",
                                    128
                                  ),
                                },
                              }}
                              className="uk-width-expand"
                            />
                          </div>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>特記事項</td>
                      <td>
                        <AttributeComponent
                          attribute={getAttribute(
                            Config.ATTRIBUTE_COLUMN_NAME_SPECIAL_NOTES
                          )}
                          className="uk-width-1-1"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>ファイル添付</td>
                      <td>
                        <FileSelect
                          name="files0"
                          rules={{
                            validate: {
                              maxSizeSum: Common.maxSizeSum,
                            },
                          }}
                          multiple
                          description="複数ファイル選択可能：合計容量は10MBまでとなります。"
                        />
                      </td>
                    </tr>
                    {showFileAttach && (
                      <>
                        <tr>
                          <td>
                            ファイル添付<span className="required">必須</span>
                          </td>
                          <td>
                            <div className="uk-grid-small uk-grid">
                              <a
                                href={require("../assets/files/利用手順書.pdf")}
                                download="利用手順書.pdf"
                              >
                                <img
                                  src={require("../assets/images/word.png")}
                                  className="width-32"
                                />
                                利用手順書
                              </a>
                              <p>：先にこちらの利用手順書をご確認ください。</p>
                            </div>
                            <div className="uk-flex uk-flex-top uk-margin-small-top">
                              <div className="uk-width-small uk-flex uk-flex-middle">
                                <a
                                  href={require("../assets/files/利用申請書.docx")}
                                  download="利用申請書.docx"
                                >
                                  <img
                                    src={require("../assets/images/word.png")}
                                    className="width-32"
                                  />
                                  利用申請書
                                </a>
                              </div>
                              <FileSelect
                                name="files1"
                                rules={{
                                  required: Config.MESSAGE_NO_E08("利用申請書"),
                                  validate: {
                                    maxSizeSum: Common.maxSizeSum,
                                  },
                                }}
                                multiple
                              />
                            </div>
                            <p className="uk-margin-small-top">
                              地方整備局等へ申請した申請書（写し）を、添付ご提出ください。
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            ファイル添付<span className="required">必須</span>
                          </td>
                          <td>
                            <p>
                              地方整備局等よりいただいた承諾書（写し）を、添付ご提出ください。
                            </p>
                            <div className="uk-grid-small uk-grid uk-margin-small-top">
                              <div className="uk-width-small"></div>
                              <FileSelect
                                name="files2"
                                rules={{
                                  required: Config.MESSAGE_NO_E08("承諾書"),
                                  validate: {
                                    maxSizeSum: Common.maxSizeSum,
                                  },
                                }}
                                multiple
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            ファイル添付<span className="required">必須</span>
                          </td>
                          <td>
                            <div className="uk-flex uk-flex-top">
                              <div className="uk-width-small uk-flex uk-flex-middle">
                                <a
                                  href={require("../assets/files/複製依頼書.docx")}
                                  download="複製依頼書.docx"
                                  className="uk-margin-right"
                                >
                                  <img
                                    src={require("../assets/images/word.png")}
                                    className="width-32"
                                  />
                                  複製依頼書
                                </a>
                              </div>
                              <FileSelect
                                name="files3"
                                rules={{
                                  required: Config.MESSAGE_NO_E08("複製依頼書"),
                                }}
                                multiple
                              />
                            </div>
                            <p className="uk-margin-small-top">
                              複製依頼書をダウンロード頂き、必要事項ご記入の上、添付ご提出ください。
                            </p>
                          </td>
                        </tr>
                      </>
                    )}
                    {renderPersonalInformationHandling()}
                  </tbody>
                </table>
                <div className="uk-flex uk-flex-center uk-margin-top uk-margin-small-bottom">
                  <button
                    className="uk-button--l uk-button-refer uk-margin-left"
                    type="submit"
                  >
                    入力内容を確認
                  </button>
                </div>
              </div>
            </div>
          )}
        </form>
      </FormProvider>
      <div className="estimate-window">
        <table>
          <thead>
            <tr>
              <th colSpan={2}>
                見積ウィンドウ
                <span
                  data-uk-icon={`icon: chevron-${
                    showEstimateWindow ? "down" : "up"
                  }; ratio: 1.5`}
                ></span>
                <div
                  className="uk-position-cover"
                  onClick={() => setShowEstimateWindow(!showEstimateWindow)}
                ></div>
              </th>
            </tr>
          </thead>
          {showEstimateWindow && (
            <tbody>
              <tr>
                <td>問合せ者区分</td>
                <td>
                  {watchInquirerType && Common.extractValue(watchInquirerType)}
                </td>
              </tr>
              <tr>
                <td>団体区分</td>
                <td>{watchGroupType && Common.extractValue(watchGroupType)}</td>
              </tr>
              <tr>
                <td>申込みエリア</td>
                <td>
                  {Common.getSelectedAreaList(
                    watchApplyAreasList,
                    areas.current
                  )
                    .map((area) => area.name)
                    .join(",")}
                </td>
              </tr>
              <tr>
                <td>DB種類</td>
                <td>
                  {watchDBType && (
                    <div>
                      <p>
                        {Common.extractValue(watchDBType)}
                        <br />
                        **03版
                        <br />
                        ※提供可能な最新年度の3月版となります。
                      </p>
                    </div>
                  )}
                </td>
              </tr>
              <tr>
                <td>見積価格（税別）</td>
                <td>{Common.formatPrice(estimateAmount)}円</td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
      <ConfirmModal
        text={"期限切れました。"}
        confirmButtonText="OK"
        isShow={isShowExpiredModal}
        onConfirm={() => {
          navigate("/apply_mail_input");
        }}
      />
    </div>
  );
}
