import React, { useEffect, useState } from 'react';
import * as Config from '@/config'

export default function Header() {
  return (
    <header>
      <a href="https://www.drm.jp/"><img src={require('../assets/images/logo.png')} alt="Avatar" /></a>
      <p className="uk-margin-small-left uk-margin-remove-vertical uk-text-default">一般財団法人</p>
      <p className="uk-margin-small-left uk-margin-remove-vertical uk-text-medium">日本デジタル道路地図協会</p>
      {Config.HEADER_ENV !== "" && <span className="uk-margin-small-left env-label">{Config.HEADER_ENV}</span>}
    </header>
  );
}