import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useLoadingStore } from "@/stores/loading";
import { sendEmailAddress } from "@/api";
import { isInvalidEmail } from "@/utils/common";
import { APPLY_FORM_URL, MESSAGE_NO_E37 } from "@/config";
import { useMessageModalStore } from "@/stores/message";
import { useState } from "react";

export default function ApplyMailInput() {
  const [isAgreed, setIsAgreed] = useState(false);
  const navigate = useNavigate();
  const setLoading = useLoadingStore((state) => state.setLoading);
  const setMessage = useMessageModalStore((state) => state.setMessage);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    let email = data["email"];
    if (!email) return;
    setLoading(true);
    const formUrl = `${APPLY_FORM_URL}?email_address=${email}`;
    sendEmailAddress(email, formUrl)
      .then((result) => {
        setLoading(false);
        navigate("/apply_mail_sent");
      })
      .catch((error) => {
        setMessage(MESSAGE_NO_E37);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="uk-flex uk-flex-column uk-flex-middle">
        <div className="sub-header">
          <p className="uk-text-large uk-text-bold">DRM-DB 申込</p>
        </div>
        <div className="uk-flex uk-width-3-4 uk-margin-top">
          <div className="step-content">
            <ul className="step">
              <li className="is-current">メールアドレスの入力</li>
              <li>メールアドレスの送信完了</li>
              <li>お客様情報の登録</li>
              <li>登録内容の確認</li>
              <li>お申し込みの完了</li>
            </ul>
          </div>
        </div>

        <div className="uk-flex uk-flex-column uk-flex-middle uk-margin-top">
          <p className="uk-margin-remove-bottom uk-text-medium">
            下記入力フォームにメールアドレスを入力頂き、送信ボタンを押してください。
            <br />
            ご指定アドレス宛に申込フォームURLを送らせて頂きます。
          </p>
        </div>
        <table className="form-tbl margin-top-40 width-700">
          <tr>
            <td>
              メールアドレス<span className="required">必須</span>
            </td>
            <td>
              <input
                className={`uk-width-1-1 ${errors.email ? "error-form" : ""}`}
                {...register("email", {
                  required: "メールアドレスが空欄です。",
                  validate: isInvalidEmail,
                })}
              />
              <div className="uk-flex uk-flex-between uk-flex-middle">
                <p>例）xx_xx@drm.or.jp</p>
                {errors.email && (
                  <div className="error">{`${errors.email.message}`}</div>
                )}
              </div>
            </td>
          </tr>
        </table>

        <div className="uk-flex uk-flex-middle uk-margin-medium-top">
          <a href="/sale_apply/DRM-PF利用規約.pdf" target="_blank">
            DRM-DX利用規約&nbsp;&nbsp;
            <img src="/sale_apply/ic_link.svg" width={16} height={16} />
          </a>
        </div>
        <label className="uk-margin-top">
          <input
            type="checkbox"
            id="agree"
            name="agree"
            onChange={() => setIsAgreed(!isAgreed)}
          />
          DRM-DX 利用規約に同意する
        </label>

        <div className="uk-margin-top">
          <button
            disabled={!isAgreed}
            className={`uk-button--l ${
              isAgreed ? "uk-button-refer" : "uk-button-disabled"
            }`}
            type="submit"
          >
            送信する
          </button>
        </div>
      </div>
    </form>
  );
}
