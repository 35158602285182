import { useEffect } from "react";

// Updates the height of a <textarea> when the value changes.
// const useAutosizeTextArea = (
//   textAreaRef: HTMLTextAreaElement | null,
//   value: string
// ) => {
//   useEffect(() => {
//     if (textAreaRef) {
//       // We need to reset the height momentarily to get the correct scrollHeight for the textarea
//       textAreaRef.style.height = "0px";
//       const scrollHeight = textAreaRef.scrollHeight;
//
//       // We then set the height directly, outside of the render loop
//       // Trying to set this with state or a ref will product an incorrect value.
//       textAreaRef.style.height = scrollHeight + "px";
//     }
//   }, [textAreaRef, value]);
// };

const useAutosizeTextArea = (
  textArea: (HTMLTextAreaElement | null) | string,
  value: string
) => {
  useEffect(() => {
    const textAreaRef = typeof textArea === 'string' ? document.getElementById(textArea) as HTMLTextAreaElement | null : textArea;

    if (textAreaRef) {
      textAreaRef.style.height = '0px';
      const scrollHeight = textAreaRef.scrollHeight;
      textAreaRef.style.height = `${scrollHeight}px`;
    }
  }, [textArea, value]);
};

export default useAutosizeTextArea;
