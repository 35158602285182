import {useLoadingStore} from '@/stores/loading';
import Header from './components/Header';
import {
  createBrowserRouter, 
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
} from "react-router-dom";
import * as Config from '@/config'
import ApplyMailInput from "./containers/ApplyMailInput";
import Apply from "./containers/Apply";
import ApplyConfirm from "./containers/ApplyConfirm";
import ApplyComplete from "./containers/ApplyComplete";
import ApplyMailSent from "./containers/ApplyMailSent";
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import Footer from './components/Footer';
import { useEffect } from "react";
import { useMessageModalStore } from './stores/message';
import ConfirmModal from './components/ConfirmModal';
import Ticket from "@/containers/Ticket/Ticket";
UIkit.use(Icons);

function App() {

  useEffect(() => {
    document.title = Config.TITLE;
  }, []);
  // TODO: react-router v6対応
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<Layout />}>
        <Route path="/" element={<ApplyMailInput />} />
        <Route path="/apply_mail_input" element={<ApplyMailInput />} />
        <Route path="/apply_mail_sent" element={<ApplyMailSent />} />
        <Route path="/apply" element={<Apply />} />
        <Route path="/apply_confirm" element={<ApplyConfirm />} />
        <Route path="/apply_complete" element={<ApplyComplete />} />
        <Route path="/ticket/:param" element={<Ticket />} />
      </Route>
    ),
    {
      basename: Config.BASENAME,
    }
  );

  return <RouterProvider router={router} />;
}

const Layout = () => {
  return (
    <>
      <Loading />
      <MessageModal />
      <Header />
      <div id="modal-root" />
      <Outlet />
      <Footer />
    </>
  )
}

function Loading() {
  const isLoading = useLoadingStore(state => state.isLoading);
  return isLoading ?
      <div
          className="uk-position-fixed uk-position-cover uk-flex uk-flex-center uk-flex-middle"
          style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', zIndex: 1000}}
      >
          <div className="uk-position-center">
              <span uk-spinner="ratio: 5"></span>
          </div>
      </div> : null
}

function MessageModal() {
  const [message, setMessage] = useMessageModalStore(state => [state.message, state.setMessage]);
  return (
    <ConfirmModal
      text={message}
      confirmButtonText="OK"
      isShow={message !== ""}
      onConfirm={() => {
        setMessage("");
      }}
    />
  )
}

export default App;
